import React from 'react';
import Returns from '../components/CallCard/Return';

const Tickets: React.FC = () => {
  return (
    <div>
      <div className="bg-white rounded-xl shadow-sm border border-zinc-200 overflow-hidden">
          <Returns />
        </div>
    </div>
  );
};

export default Tickets;
