import axiosInstance from './axiosInstance'

function setAuthorizationHeader(token: string | null = null) {
    axiosInstance.defaults.headers['X-Secret-Code'] = "fyncall@1411Fali"
    axiosInstance.defaults.headers['X-Secret-Key'] = "fyncall@1411"
    if (token) {
        axiosInstance.defaults.headers.Authorization = `Bearer ${token}`
    } else {
        delete axiosInstance.defaults.headers['x-auth-token']
    }
}

// Adding token to the header
export { setAuthorizationHeader }
