import { useRoutes } from 'react-router-dom';
import { getItem } from './services/localStorage.service';
import { routes } from './utils/routes';

function App() {
  const token = getItem("evelixtoken");
  const routing = useRoutes(routes(!!token));
  
  return <>{routing}</>;
}

export default App;