import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import CallDetailCard from "../components/CallDetailCard/CallDetailCard";
import ApiService from "../services/api.service";
import { Input } from "../components/forms/Input";
import { Loader } from "../components/ui/Loader";
import { Search } from "react-feather";
import { ENDED_REASONS } from "../utils/constants";

interface CallLog {
  id: string;
  customer: {
    name: string | null;
    number: string | null;
  };
  startedAt: string | null;
  endedAt: string | null;
  endedReason: keyof typeof ENDED_REASONS | null;
  transcript: string | null;
  type: string | null;
  summary?: string;
}

type SortOrder = "asc" | "desc";
type SortField = "customerName" | "startedAt" | "duration";

const service = new ApiService();

function CallLogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    field: SortField;
    order: SortOrder;
  }>({
    field: "startedAt",
    order: "desc",
  });

  const { data: callHistory = [], isLoading } = useQuery<CallLog[]>(
    "calls",
    async () => {
      const response = await service.getCallLogs();
      return Array.isArray(response.data.data) ? response.data.data : [];
    },
    {
      staleTime: 60000,
      cacheTime: 300000,
      refetchOnWindowFocus: true,
    }
  );

  const calculateDuration = (call: CallLog): number => {
    if (!call.startedAt || !call.endedAt) return 0;
    const start = new Date(call.startedAt).getTime();
    const end = new Date(call.endedAt).getTime();
    return (end - start) / (1000 * 60);
  };

  const filteredCalls = useMemo(() => {
    let filtered = [...callHistory];

    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      filtered = filtered.filter((call) =>
        call.customer.name?.toLowerCase().includes(searchLower)
      );
    }

    const sorted = [...filtered].sort((a, b) => {
      let comparison = 0;

      switch (sortConfig.field) {
        case "customerName":
          comparison = (a.customer.name || "").localeCompare(
            b.customer.name || ""
          );
          break;
        case "startedAt":
          comparison =
            new Date(a.startedAt || "").getTime() -
            new Date(b.startedAt || "").getTime();
          break;
        case "duration":
          comparison = calculateDuration(a) - calculateDuration(b);
          break;
        default:
          comparison = 0;
      }

      return sortConfig.order === "asc" ? comparison : -comparison;
    });

    return sorted;
  }, [callHistory, searchQuery, sortConfig]);

  const handleSort = (field: SortField) => {
    const newOrder: SortOrder =
      sortConfig.field === field && sortConfig.order === "asc"
        ? "desc"
        : "asc";

    setSortConfig({ field, order: newOrder });
  };

  const renderSortIndicator = (field: SortField) => {
    if (sortConfig.field !== field) return null;
    return <span className="ml-1">{sortConfig.order === "asc" ? "↑" : "↓"}</span>;
  };

  return (
    <div className="w-full flex flex-col items-start ">
      <div className="w-full border rounded-lg p-4 bg-white mb-5 shadow-sm">
        <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <div className="relative flex-1 max-w-md">
            <Input
              type="text"
              placeholder="Search by customer name..."
              value={searchQuery}
              className="pl-10"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchQuery(e.target.value)
              }
            />
            <Search
              size={20}
              className="absolute left-3 top-3.5 text-nutral-400"
            />
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => handleSort("customerName")}
              className={`px-4 py-2 border rounded-lg shadow-sm ${
                sortConfig.field === "customerName"
                  ? "bg-blue-50 border-blue-500 text-blue-700"
                  : "text-zinc-800 hover:bg-gray-50"
              }`}
            >
              Customer Name {renderSortIndicator("customerName")}
            </button>
            <button
              onClick={() => handleSort("startedAt")}
              className={`px-4 py-2 border rounded-lg shadow-sm ${
                sortConfig.field === "startedAt"
                  ? "bg-blue-50 border-blue-500 text-blue-700"
                  : "text-zinc-800 hover:bg-gray-50"
              }`}
            >
              Date {renderSortIndicator("startedAt")}
            </button>
            <button
              onClick={() => handleSort("duration")}
              className={`px-4 py-2 border rounded-lg shadow-sm ${
                sortConfig.field === "duration"
                  ? "bg-blue-50 border-blue-500 text-blue-700"
                  : "text-zinc-800 hover:bg-gray-50"
              }`}
            >
              Duration {renderSortIndicator("duration")}
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-col gap-5">
          {filteredCalls.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No calls found matching your search criteria
            </div>
          ) : (
            filteredCalls.map((item) => (
              <CallDetailCard
                key={item.id}
                call={item}
                additionalCalls={[]}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default CallLogs;
