// API Constants
export const API_ENDPOINTS = {
    AUTH: {
      LOGIN: '/auth/login',
      REGISTER: '/auth/register',
      FORGOT_PASSWORD: '/auth/forgot-password',
      RESET_PASSWORD: '/auth/reset-password',
    },
    CONVERSATIONS: {
      LIST: '/conversations',
      CREATE: '/conversations/create',
      GET: (id: string) => `/conversations/${id}`,
      UPDATE: (id: string) => `/conversations/${id}`,
      DELETE: (id: string) => `/conversations/${id}`,
    },
    USERS: {
      LIST: '/users',
      CREATE: '/users/create',
      GET: (id: string) => `/users/${id}`,
      UPDATE: (id: string) => `/users/${id}`,
      DELETE: (id: string) => `/users/${id}`,
    },
  };

export const ENDED_REASONS: any = {
  'customer-did-not-answer': 'Customer did not answer the call',
  'customer-ended-call': 'Customer Ended Call',
  'customer-busy': 'Customer Busy',
  'assistant-said-end-call-phrase': 'Assistant Said End Call Phrase',
  'twilio-failed-to-connect-call': 'Twilio Failed to Connect Call',
  'customer-did-not-give-microphone-permission': 'Customer Did Not Give Microphone Permission',
  voicemail: 'Voicemail',
  'silence-timed-out': 'Silence Timed Out',
}


  export const STORAGE_KEYS = {
    TOKEN: 'evelix_token',
    USER: 'evelix_user',
    THEME: 'evelix_theme',
    SETTINGS: 'evelix_settings',
  };
  
  // Conversation Status
  export const CONVERSATION_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    COMPLETED: 'completed',
  } as const;
  
  // User Roles
  export const USER_ROLES = {
    ADMIN: 'admin',
    USER: 'user',
  } as const;
  
  // Theme Options
  export const THEME_OPTIONS = {
    LIGHT: 'light',
    DARK: 'dark',
    SYSTEM: 'system',
  } as const;
  
  // Message Types
  export const MESSAGE_TYPES = {
    TEXT: 'text',
    IMAGE: 'image',
    FILE: 'file',
    SYSTEM: 'system',
  } as const;
  
  // Validation Rules
  export const VALIDATION = {
    PASSWORD_MIN_LENGTH: 8,
    NAME_MIN_LENGTH: 2,
    MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
    ALLOWED_FILE_TYPES: ['.pdf', '.doc', '.docx', '.txt'],
  };
  
  // Error Messages
  export const ERROR_MESSAGES = {
    INVALID_CREDENTIALS: 'Invalid email or password',
    NETWORK_ERROR: 'Unable to connect to server',
    UNAUTHORIZED: 'You are not authorized to perform this action',
    INVALID_TOKEN: 'Your session has expired. Please login again',
    INVALID_FILE_TYPE: 'Invalid file type',
    FILE_TOO_LARGE: 'File size exceeds the maximum limit',
  };
  
  // Success Messages
  export const SUCCESS_MESSAGES = {
    LOGIN_SUCCESS: 'Login successful',
    LOGOUT_SUCCESS: 'Logout successful',
    SETTINGS_UPDATED: 'Settings updated successfully',
    PASSWORD_RESET: 'Password reset successful',
    USER_CREATED: 'User created successfully',
    USER_UPDATED: 'User updated successfully',
    USER_DELETED: 'User deleted successfully',
  };
  
  // Timeouts
  export const TIMEOUTS = {
    TOAST_DURATION: 5000,
    SESSION_TIMEOUT: 30 * 60 * 1000, // 30 minutes
    DEBOUNCE_DELAY: 300,
    API_TIMEOUT: 30000, // 30 seconds
  };