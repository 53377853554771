import { useNotifications } from '../../hooks/useNotifications'
import { api } from '../../pages/Knowledge'
import React, { useState } from 'react'
import { Upload } from 'react-feather'

export const UploadZone = ({ fetchFiles }: { fetchFiles: () => Promise<void> }) => {
    const [uploadProgress, setUploadProgress] = useState(0)
    const [dragActive, setDragActive] = useState(false)

    const { successMessage, errorMessage } = useNotifications()

    const handleUpload = async (file: File) => {
        try {
            setUploadProgress(0)
            await api.uploadFile(file, progress => setUploadProgress(progress))
            await fetchFiles()
            successMessage('File uploaded successfully')
        } catch (error) {
            errorMessage(
                error instanceof Error
                    ? error.message
                    : 'Failed to upload file',
            )
        }
    }

    const handleDrop = async (e: React.DragEvent) => {
        e.preventDefault()
        setDragActive(false)
        const file = e.dataTransfer.files[0]
        if (file) await handleUpload(file)
    }

    return (
        <div
            className={`border-2 border-dashed rounded-lg p-8 mb-8 text-center
      	${dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
      	transition-colors duration-200`}
            onDragOver={e => {
                e.preventDefault()
                setDragActive(true)
            }}
            onDragLeave={() => setDragActive(false)}
            onDrop={handleDrop}>
            <input
                type="file"
                id="file-upload"
                className="hidden"
                onChange={e =>
                    e.target.files?.[0] && handleUpload(e.target.files[0])
                }
                accept=".txt,.doc,.docx,.pdf,.rtf,.odt"
            />
            <label htmlFor="file-upload" className="cursor-pointer">
                <Upload className="h-12 w-12 mx-auto text-gray-400 mb-4" />
                <p className="text-gray-600 mb-2">
                    Drag and drop files here, or{' '}
                    <span className="text-blue-500 hover:text-blue-600">
                        browse
                    </span>
                </p>
                <p className="text-sm text-gray-500">
                    Supported formats: TXT, DOC, DOCX, PDF, RTF, ODT (Max 50MB)
                </p>
            </label>

            {uploadProgress > 0 && (
                <div className="mt-4 max-w-md mx-auto">
                    <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                            className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                            style={{ width: `${uploadProgress}%` }}
                        />
                    </div>
                    <p className="text-sm text-gray-600 mt-2">
                        {Math.round(uploadProgress)}% uploaded
                    </p>
                </div>
            )}
        </div>
    )
}

