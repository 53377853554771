import classNames from "classnames";

export const Input = ({
  type,
  value,
  placeholder,
  name,
  className,
  container,
  onKeyDown,
  label,
  labelStyle = "text-white",
  onChange,
  ...rest
}: any) => {
  return (
    <div className={classNames("flex flex-col gap-1.5", container)}>
      {label && <label className={classNames("font-Helvetica", labelStyle)}>{label}</label>}
      {type === "textarea" ? (
        <textarea
          placeholder={placeholder}
          onChange={e => onChange(e)} // Pass full event here
          className={styles.input}
          {...rest}
        />
      ) : (
        <input
          name={name}
          value={value}
          type={type}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onChange={e => onChange(e)} // Pass full event here
          className={classNames(styles.input, className)}
          {...rest}
        />
      )}
    </div>
  );
};

export const styles = {
  input:
    "rounded-lg shadow-sm px-3 py-2.5 text-zinc-700 w-full border focus:border-primary/50 border-zinc-200 focus:ring focus:ring-primary focus:outline-none focus:ring-opacity-20 focus:backdrop-blur-md",
};
