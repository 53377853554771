import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Search, Filter, ArrowUpDown } from 'lucide-react'
import { useQuery } from 'react-query'
import ApiService from '../../services/api.service'
import { Input, styles } from '../forms/Input'
import { Call } from '../../types'
import { Loader } from '../ui/Loader'

const service = new ApiService()

type SortField = 'startedAt' | 'customer' | 'type' | 'status'
type FilterStatus = 'all' | 'New' | 'Pending' | 'Completed'

const Returns = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const [selectedFilter, setSelectedFilter] = useState<FilterStatus>('all')
    const [sort, setSort] = useState<{
        field: SortField
        direction: 'asc' | 'desc'
    }>({
        field: 'startedAt',
        direction: 'desc',
    })
    const [selectedCalls, setSelectedCalls] = useState<string[]>([])

    const { data: callHistory = [], isLoading } = useQuery<Call[]>(
        'calls',
        async () => {
            const response = await service.getCallLogs()
            return Array.isArray(response.data.data) ? response.data.data : []
        },
        {
            staleTime: 60000,
            cacheTime: 300000,
            refetchOnWindowFocus: true,
        },
    )

    const getCallStatus = (call: Call): FilterStatus => {
        const today = new Date().toISOString().split('T')[0]
        const createdAt = new Date(call.createdAt).toISOString().split('T')[0]

        if (call.actionsStatus === 'pending' && today === createdAt) {
            return 'New'
        }
        if (call.actionsStatus === 'no_action_needed') {
            return 'Completed'
        }
        return 'Pending'
    }

    const formatTime = (dateString: string): string => {
        const date = new Date(dateString)
        const now = new Date()
        const diffInHours = Math.floor(
            (now.getTime() - date.getTime()) / (1000 * 60 * 60),
        )

        if (diffInHours < 24) {
            return date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
            })
        } else if (diffInHours < 48) {
            return '1 day ago'
        } else {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
            })
        }
    }

    const filterCalls = (calls: Call[]): Call[] => {
        let filtered = [...calls];

        if (search) {
            const searchLower = search.toLowerCase();
            filtered = filtered.filter(
                (call) =>
                    call.customer?.name?.toLowerCase().includes(searchLower) ||
                    call.customer?.number?.includes(searchLower) ||
                    call.summary?.toLowerCase().includes(searchLower)
            );
        }

        if (selectedFilter !== "all") {
            filtered = filtered.filter(
                (call) => getCallStatus(call) === selectedFilter
            );
        }

        return filtered;
    };


    const sortCalls = (calls: Call[]): Call[] => {
        return [...calls].sort((a, b) => {
            const direction = sort.direction === "asc" ? 1 : -1;

            if (sort.field === "startedAt") {
                return (
                    direction *
                    (new Date(a.startedAt || 0).getTime() -
                        new Date(b.startedAt || 0).getTime())
                );
            }

            if (sort.field === "customer") {
                return (
                    direction *
                    (a.customer?.name || "").localeCompare(b.customer?.name || "")
                );
            }

            if (sort.field === "status") {
                return (
                    direction *
                    (a.actionsStatus || "").localeCompare(b.actionsStatus || "")
                );
            }

            return direction * (a.type || "").localeCompare(b.type || "");
        });
    };


    const filteredCalls = useMemo(() => {
        const filtered = filterCalls(callHistory)
        return sortCalls(filtered)
    }, [callHistory, search, selectedFilter, sort])

    const handleSort = (field: SortField) => {
        setSort(prev => ({
            field,
            direction:
                prev.field === field && prev.direction === 'asc'
                    ? 'desc'
                    : 'asc',
        }))
    }

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedCalls(filteredCalls.map(call => call.id))
        } else {
            setSelectedCalls([])
        }
    }

    return (
        <div className="w-full bg-white rounded-xl shadow-sm">
            <div className="p-6 border-b">
                <div className="flex items-center justify-between mb-6">
                    <div>
                        <h1 className="text-xl font-semibold text-gray-800">
                            Call History
                        </h1>
                        <p className="text-sm text-gray-500 mt-1">
                            {filteredCalls.length} calls total
                        </p>
                    </div>
                    <div className="flex gap-3">
                        {selectedCalls.length > 0 && (
                            <button className="px-4 py-2 bg-blue-50 text-blue-600 rounded-lg text-sm font-medium">
                                Process Selected ({selectedCalls.length})
                            </button>
                        )}
                    </div>
                </div>

                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                    <div className="relative flex-1 max-w-md">
                        <Input
                            type="text"
                            placeholder="Search calls..."
                            value={search}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setSearch(e.target.value)
                            }
                            className="pl-10"
                        />

                        <Search
                            size={20}
                            className="absolute left-3 top-3.5 text-nutral-400"
                        />
                    </div>

                    <div className="flex gap-2">
                        <select
                            value={selectedFilter}
                            onChange={e =>
                                setSelectedFilter(
                                    e.target.value as FilterStatus,
                                )
                            }
                            className={styles.input}>
                            <option value="all">All Status</option>
                            <option value="New">New</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Completed</option>
                        </select>

                        <button className="p-2 border rounded-lg shadow-sm hover:bg-gray-50">
                            <Filter className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="p-4 w-4">
                                <input
                                    type="checkbox"
                                    className="rounded border-gray-300"
                                    checked={
                                        selectedCalls.length ===
                                        filteredCalls.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </th>
                            {[
                                'Status',
                                'Customer',
                                'Summary',
                                'Started At',
                                'Type',
                            ].map(header => (
                                <th
                                    key={header}
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                                    onClick={() =>
                                        handleSort(
                                            header.toLowerCase() as SortField,
                                        )
                                    }>
                                    <div className="flex items-center gap-2">
                                        {header}
                                        <ArrowUpDown className="h-4 w-4" />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {isLoading ? (
                            <tr>
                                <td
                                    colSpan={6}
                                    className="px-6 py-12 text-center">
                                    <Loader message="Loading calls..." />
                                </td>
                            </tr>
                        ) : (
                            filteredCalls.map(call => (
                                <tr
                                    key={call.id}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/tickets/${call.id}`,
                                            {
                                                state: { ticket: call },
                                            },
                                        )
                                    }
                                    className="hover:bg-gray-50 cursor-pointer">
                                    <td
                                        className="p-4"
                                        onClick={e => e.stopPropagation()}>
                                        <input
                                            type="checkbox"
                                            className="rounded border-gray-300"
                                            checked={selectedCalls.includes(
                                                call.id,
                                            )}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    setSelectedCalls(prev => [
                                                        ...prev,
                                                        call.id,
                                                    ])
                                                } else {
                                                    setSelectedCalls(prev =>
                                                        prev.filter(
                                                            id =>
                                                                id !== call.id,
                                                        ),
                                                    )
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span
                                            className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${getCallStatus(call) === 'New'
                                                    ? 'bg-yellow-100 text-yellow-800'
                                                    : getCallStatus(call) ===
                                                        'Completed'
                                                        ? 'bg-green-100 text-green-800'
                                                        : 'bg-blue-100 text-blue-800'
                                                }`}>
                                            {getCallStatus(call)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {call.customer.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {call.summary?.slice(0, 40) || 'N/A'}...
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {formatTime(call.startedAt)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {call.type}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {/* Pagination */}
            <div className="px-6 py-4 flex items-center justify-between border-t">
                <div className="text-sm text-gray-500">
                    Showing {filteredCalls.length} of {callHistory.length} calls
                </div>
                <div className="flex gap-2">
                    <button className="px-3 py-1 border rounded hover:bg-gray-50">
                        &larr;
                    </button>
                    <button className="px-3 py-1 bg-blue-50 text-blue-600 rounded">
                        1
                    </button>
                    <button className="px-3 py-1 border rounded hover:bg-gray-50">
                        2
                    </button>
                    <button className="px-3 py-1 border rounded hover:bg-gray-50">
                        3
                    </button>
                    <button className="px-3 py-1 border rounded hover:bg-gray-50">
                        &rarr;
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Returns
