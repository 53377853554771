import React from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

interface ToggleButtonProps {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  label?: string;
  description?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  enabled,
  onChange,
  label,
  description,
  disabled = false,
  size = 'md',
}) => {
  const sizes = {
    sm: {
      switch: 'h-5 w-9',
      translate: 'translate-x-4',
      circle: 'h-3 w-3',
    },
    md: {
      switch: 'h-6 w-11',
      translate: 'translate-x-5',
      circle: 'h-4 w-4',
    },
    lg: {
      switch: 'h-7 w-14',
      translate: 'translate-x-7',
      circle: 'h-5 w-5',
    },
  };

  return (
    <Switch.Group>
      <div className="flex items-center">
        {(label || description) && (
          <div className="mr-3">
            {label && (
              <Switch.Label className="text-sm font-medium text-gray-700">
                {label}
              </Switch.Label>
            )}
            {description && (
              <p className="text-sm text-gray-500">{description}</p>
            )}
          </div>
        )}
        <Switch
          checked={enabled}
          onChange={onChange}
          disabled={disabled}
          className={classNames(
            enabled ? 'bg-primary' : 'bg-gray-200',
            disabled && 'opacity-50 cursor-not-allowed',
            'relative inline-flex flex-shrink-0 rounded-full cursor-pointer transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
            sizes[size].switch
          )}
        >
          <span className="sr-only">Toggle</span>
          <span
            className={classNames(
              enabled ? sizes[size].translate : 'translate-x-1',
              'pointer-events-none relative inline-block rounded-full bg-white shadow transform ring-0 transition duration-200 ease-in-out',
              sizes[size].circle
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};