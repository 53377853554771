import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface NavLinkProps {
  active: boolean;
  to: string;
  icon: React.ReactNode;
  label: string;
  className?: string;
}

export const NavLink: React.FC<NavLinkProps> = ({
  active,
  to,
  icon,
  label,
  className,
}) => {
  return (
    <div className="flex items-center font-medium text-gray-700 group">
      <div
        className={classNames({
          "w-1 h-8 bg-primary rounded-r-md": active,
        })}
      />
      <Link
        to={to}
        className={classNames("flex px-5 items-center w-full", className)}
      >
        <div
          className={classNames(
            "flex items-center gap-x-3 w-full rounded-lg p-2.5 transition-colors duration-200",
            {
              "bg-primary/10 text-primary": active,
              "hover:bg-gray-100": !active,
            }
          )}
        >
          <span className={active ? "text-primary" : "text-gray-500"}>
            {icon}
          </span>
          <span>{label}</span>
        </div>
      </Link>
    </div>
  )
}
