import React from 'react';
import { AlertCircle, CheckCircle, XCircle, Info, X } from 'react-feather';
import classNames from 'classnames';

export interface AlertProps {
  type?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  message: string;
  onClose?: () => void;
  showIcon?: boolean;
  className?: string;
}

export const Alert: React.FC<AlertProps> = ({
  type = 'info',
  title,
  message,
  onClose,
  showIcon = true,
  className,
}) => {
  const styles = {
    success: {
      wrapper: 'bg-green-50 border-green-200',
      icon: 'text-green-400',
      title: 'text-green-800',
      message: 'text-green-700',
    },
    error: {
      wrapper: 'bg-red-50 border-red-200',
      icon: 'text-red-400',
      title: 'text-red-800',
      message: 'text-red-700',
    },
    warning: {
      wrapper: 'bg-yellow-50 border-yellow-200',
      icon: 'text-yellow-400',
      title: 'text-yellow-800',
      message: 'text-yellow-700',
    },
    info: {
      wrapper: 'bg-blue-50 border-blue-200',
      icon: 'text-blue-400',
      title: 'text-blue-800',
      message: 'text-blue-700',
    },
  };

  const icons = {
    success: CheckCircle,
    error: XCircle,
    warning: AlertCircle,
    info: Info,
  };

  const Icon = icons[type];

  return (
    <div
      className={classNames(
        'rounded-lg border p-4 flex items-start',
        styles[type].wrapper,
        className
      )}
    >
      {showIcon && (
        <div className={classNames('flex-shrink-0', styles[type].icon)}>
          <Icon className="h-5 w-5" />
        </div>
      )}
      <div className="ml-3 flex-1">
        {title && (
          <h3 className={classNames('text-sm font-medium', styles[type].title)}>
            {title}
          </h3>
        )}
        <div className={classNames('text-sm', styles[type].message)}>
          {message}
        </div>
      </div>
      {onClose && (
        <button
          className={classNames('ml-auto flex-shrink-0', styles[type].icon)}
          onClick={onClose}
        >
          <X className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};