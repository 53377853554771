import React, { useState } from "react";
import { useNotifications } from "../hooks/useNotifications";
import { Input } from "../components/forms/Input";
import { ButtonPrimary } from "../components/forms/ButtonPrimary";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import axiosInstance from "../utils/axiosInstance";
import { Phone, Loader2, TrendingUp, PhoneCall, Clock } from "lucide-react";
import Returns from "../components/CallCard/Return";

function Home() {
  const [phone, setPhone] = useState("");
  const [call, setCall] = useState("");
  const [name, setName] = useState("");
  const [isCalling, setIsCalling] = useState(false);
  const [lastCallTime, setLastCallTime] = useState<number | null>(null);
  const { errorMessage } = useNotifications();

  const handleClick = () => {
    const now = Date.now();

    if (isCalling) return;

    if (lastCallTime && now - lastCallTime < 5000) {
      errorMessage("Please wait 5 seconds between calls");
      return;
    }

    setIsCalling(true);
    setLastCallTime(now);

    axiosInstance
      .post("/calls/create", { phone_number: `+${phone}`, name })
      .then((resp) => {
        setCall(resp.data.message);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          errorMessage(error?.response?.data.detail);
        } else {
          errorMessage(error?.response?.data?.detail?.message);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setIsCalling(false);
        }, 5000);
      });
  };

  const isValidForm = phone.length > 0 && name.length > 0;

  return (
    <div className="min-h-screen w-full ">
      <div className=" mx-auto space-y-8">
        <div className="grid grid-cols-1 xl:grid-cols-4 gap-6">
          <div className="xl:col-span-2">
            <div className="bg-white rounded-xl shadow-sm border border-zinc-200 ">
              <div className="p-6">
                <h1 className="text-xl font-semibold text-zinc-800 mb-3">
                  Make a Call
                </h1>

                <div className="grid sm:grid-cols-2 gap-5">
                <div className="">
                                        <label className="block text-sm font-medium text-zinc-700 mb-1.5">
                                            Phone Number
                                        </label>
                                        <PhoneInput
                                            country="us"
                                            value={phone}
                                            onChange={setPhone}
                                            containerClass="phone-input"
                                            inputClass="!w-full !h-11 !text-base !rounded-lg"
                                            buttonClass="!h-11 !rounded-l-lg"
                                            placeholder="Enter phone number"
                                        />
                                    </div>

                  <Input
                    container="w-full"
                    label="Customer Name"
                    labelStyle="text-sm font-medium text-zinc-700"
                    placeholder="Enter customer name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setName(e.target.value)
                    }
                  />

                  <div className="sm:col-span-2">
                    <ButtonPrimary
                      onClick={handleClick}
                      label={
                        isCalling ? (
                          <>
                            <Loader2 className="h-5 w-5 animate-spin" />
                            <span>Calling...</span>
                          </>
                        ) : (
                          <>
                            <Phone className="h-5 w-5" />
                            <span className="text-white">Make Call</span>
                          </>
                        )
                      }
                      disable={!isValidForm || isCalling}
                      className="w-full !h-11 flex items-center justify-center gap-2 transition-all"
                    />
                  </div>

                  {call && (
                    <div
                      className={`sm:col-span-2 p-4 rounded-lg ${
                        isCalling
                          ? "bg-blue-50 text-blue-700"
                          : "bg-green-50 text-green-700"
                      }`}
                    >
                      <p className="text-sm font-medium">{call}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="xl:col-span-2 grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-white rounded-xl shadow-sm border border-zinc-200">
              <div className="h-full p-6 flex flex-col items-center justify-center text-center">
                <div className="bg-blue-50 p-3 rounded-lg mb-3">
                  <PhoneCall className="h-6 w-6 text-blue-600" />
                </div>
                <div className="space-y-1">
                  <div className="text-sm font-medium text-zinc-600">
                    Today's Calls
                  </div>
                  <div className="text-2xl font-semibold text-zinc-800">12</div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm border border-zinc-200">
              <div className="h-full p-6 flex flex-col items-center justify-center text-center">
                <div className="bg-green-50 p-3 rounded-lg mb-3">
                  <TrendingUp className="h-6 w-6 text-green-600" />
                </div>
                <div className="space-y-1">
                  <div className="text-sm font-medium text-zinc-600">
                    Success Rate
                  </div>
                  <div className="text-2xl font-semibold text-zinc-800">
                    95%
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-sm border border-zinc-200">
              <div className="h-full p-6 flex flex-col items-center justify-center text-center">
                <div className="bg-purple-50 p-3 rounded-lg mb-3">
                  <Clock className="h-6 w-6 text-purple-600" />
                </div>
                <div className="space-y-1">
                  <div className="text-sm font-medium text-zinc-600">
                    Avg Duration
                  </div>
                  <div className="text-2xl font-semibold text-zinc-800">
                    2.5m
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-white rounded-xl shadow-sm border border-zinc-200 overflow-hidden">
          <Returns />
        </div> */}
      </div>
    </div>
  );
}

export default Home;
