import { ButtonPrimary } from '../components/forms/ButtonPrimary';
import { ToggleButton } from '../components/forms/ToggleButton';
import { Fragment, useState } from 'react';
import { X } from 'react-feather';
import { Input } from '../components/forms/Input';

export const Settings = () => {
    const [state, setState] = useState({
        name: '',
        message: '',
        voiceMessage: '',
        endCall: '',
        language: '',
        model: '',
        provider: '',
        voiceProvider: '',
        voiceId: '',
        voiceModel: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setState({
            ...state,
            [name]: value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Submit', state);
    };

    return (
           
            <div className="bg-white border rounded-lg p-6 shadow-sm ">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 ">
                    <p className="text-nutral-700 font-HelveticaB border-b border-zinc-200 pb-3">
                        Basic Information
                    </p>
                    <div className="grid grid-cols-2 w-full gap-4">
                        <Input
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            label="Assistant Name"
                            name="name"
                            value={state.name}
                        />
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            label="First Message"
                            name="message"
                            value={state.message}
                        />
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            label="Voicemail Message"
                            name="voiceMessage"
                            value={state.voiceMessage}
                        />
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            label="End Call Message"
                            name="endCall"
                            value={state.endCall}
                        />
                    </div>

                    <p className="text-nutral-700 font-HelveticaB border-b border-zinc-200 pb-3 mt-3">
                        Voice Settings
                    </p>
                    <div className="grid grid-cols-2 w-full gap-4">
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            value={state.voiceModel}
                            name="voiceModel"
                            label="Voice Model"
                        />
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            value={state.voiceId}
                            name="voiceId"
                            label="Voice ID"
                        />
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            value={state.voiceProvider}
                            name="voiceProvider"
                            label="Voice Provider"
                        />
                    </div>

                    <p className="text-nutral-700 font-HelveticaB border-b border-zinc-200 pb-3 mt-3">
                        Language Model Settings
                    </p>
                    <div className="grid grid-cols-2 w-full gap-4">
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            value={state.model}
                            name="model"
                            label="LLM Model"
                        />
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-700"
                            onChange={handleChange}
                            value={state.provider}
                            name="provider"
                            label="LLM Provider"
                        />
                    </div>

                    <p className="text-nutral-700 font-HelveticaB border-b border-zinc-200 pb-3 mt-3">
                        Transcriber Settings
                    </p>
                    <div className="w-full">
                        <Input
                            container="w-full"
                            labelStyle="text-nutral-800"
                            onChange={handleChange}
                            value={state.language}
                            name="language"
                            label="Language"
                        />
                    </div>

                    <p className="text-nutral-700 font-HelveticaB border-b border-zinc-200 pb-3 mt-3">
                        Additional Settings
                    </p>
                    <div className="flex flex-col gap-3 w-full ">
                        <div className="flex justify-between items-center">
                            <p className="text-nutral-700">Recording Enabled</p>
                            <ToggleButton
                                enabled={true}
                                onChange={(value) => console.log(value)}
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <p className="text-nutral-700">
                                End Call Function Enabled
                            </p>
                            <ToggleButton
                                enabled={false}
                                onChange={(value) => console.log(value)}
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <p className="text-nutral-700">Backchanneling</p>
                            <ToggleButton
                                enabled={true}
                                onChange={(value) => console.log(value)}
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <p className="text-nutral-700">
                                Background Denoising
                            </p>
                            <ToggleButton
                                enabled={false}
                                onChange={(value) => console.log(value)}
                            />
                        </div>
                        <p className="text-nutral-700 font-HelveticaB border-b border-zinc-200 pb-3 mt-3">
                            End Call Phrases
                        </p>
                        <div className="flex flex-wrap gap-2">
                            <div className="px-3 py-2 bg-violet-50 rounded-lg border border-primary/30 items-center gap-3 inline-flex">
                                <p className="text-nutral-700 text-sm font-TTHovesM whitespace-nowrap">
                                    Goodbye
                                </p>
                                <button className="" type="button">
                                    <X className="w-4 text-primary" />
                                </button>
                            </div>
                            <div className="px-3 py-2 bg-violet-50 rounded-lg border border-primary/30 items-center gap-3 inline-flex">
                                <p className="text-nutral-700 text-sm font-TTHovesM whitespace-nowrap">
                                    Talk to you soon
                                </p>
                                <button className="" type="button">
                                    <X className="w-4 text-primary" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex font-Helvetica gap-3 place-content-end mt-3">
                        <ButtonPrimary
                            className="!py-2 !text-sm"
                            disable={false}
                            type="submit"
                            label="Save"
                        />
                    </div>
                </form>
            </div>
    );
};
