import classNames from "classnames";

interface ButtonPrimaryProps {
  type?: "button" | "submit" | "reset";
  disable?: boolean;
  container?: string;
  label: React.ReactNode;
  className?: string;
  onClick?: () => void;
  variant?: "default" | "red"; // Restrict to available variants
}

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  type = "button",
  disable = false,
  container = "",
  label,
  className = "",
  onClick,
  variant = "default",
}) => {
  const variants: Record<string, string> = {
    default: "bg-primary border-primary",
    red: "bg-secondary border-secondary",
  };

  return (
    <div className={container}>
      <button
        type={type}
        disabled={disable}
        onClick={onClick}
        className={classNames(
          "text-xs md:text-base px-5 py-2.5 disabled:opacity-50 disabled:cursor-not-allowed rounded-lg shadow-sm font-Helvetica tracking-wide border text-white",
          className,
          variants[variant]
        )}
      >
        {label}
      </button>
    </div>
  );
};


interface SecondaryButtonProps {
  type?: "button" | "submit" | "reset";
  container?: string;
  onClick?: () => void;
  label: React.ReactNode;
  className?: string;
  variant?: "default" | "danger"; // Restrict to available variants
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  type = "button",
  container = "",
  onClick,
  label,
  className = "",
  variant = "default",
}) => {
  const variants: Record<string, string> = {
    danger: "hover:bg-secondary hover:text-white border-secondary text-secondary",
    default: "text-primary border-primary bg-primary-light",
  };

  return (
    <div className={container}>
      <button
        type={type}
        onClick={onClick}
        className={classNames(
          "border shadow-sm w-full font-Helvetica tracking-wide rounded-md text-xs md:text-base px-5 py-2.5",
          className,
          variants[variant]
        )}
      >
        {label}
      </button>
    </div>
  );
};


