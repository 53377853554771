import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "./NavLink";
import {
  Home,
  MessageSquare,
  BarChart2,
  Settings,
  Book,
  Users,
  LogOut,
  Search,
  Calendar,
  Phone,
  Paperclip,
} from "react-feather";
import { Input } from "../forms/Input";
import { useEffect } from "react";
import { getItem } from "../../services/localStorage.service";
import { useNavigate } from "react-router-dom";

interface User {
  username: string;
  email: string;
}

export const Sidebar: React.FC = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const data = getItem("userdata");

    if (data) {
      try {
        const resp = JSON.parse(data);
        setUser(resp);
      } catch (error) {
        console.error("Error parsing userdata:", error);
      }
    } else {
      console.log("No userdata found.");
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("evelixtoken");
    localStorage.removeItem("userdata");
    navigate("/login");
  };

  return (
    <div className="flex h-full">
      <div className="flex flex-col w-72">
        <div className="flex flex-col flex-grow bg-white border-r border-gray-200">
          <div className="flex-grow flex flex-col">
            <Link to="/" className="px-7 mt-4">
              <h1 className="text-3xl font-bold text-primary">Evelix AI</h1>
            </Link>

            {/* <div className="px-4 py-2">
              <Input
                placeholder="Search..."
                startIcon={<Search className="h-5 w-5 text-gray-400" />}
                className="bg-gray-50"
              />
            </div> */}

            <nav className="flex-1  py-4 space-y-1">
              <NavLink
                active={pathname === "/dashboard"}
                icon={<Home className="h-5 w-5" />}
                label="Dashboard"
                to="/dashboard"
              />
              <NavLink
                active={pathname.includes("/dashboard/calllogs")}
                icon={<Phone className="h-5 w-5" />}
                label="Call Logs"
                to="/dashboard/calllogs"
              />
              <NavLink
                active={pathname.includes("/dashboard/tickets")}
                icon={<Paperclip className="h-5 w-5" />}
                label="Tickets"
                to="/dashboard/tickets"
              />
              <NavLink
                active={pathname.includes("/dashboard/conversations")}
                icon={<MessageSquare className="h-5 w-5" />}
                label="Conversations"
                to="/dashboard/conversations"
              />
              <NavLink
                active={pathname === "/dashboard/analytics"}
                icon={<BarChart2 className="h-5 w-5" />}
                label="Analytics"
                to="/dashboard/analytics"
              />
              <NavLink
                active={pathname === "/dashboard/customer"}
                icon={<Users className="h-5 w-5" />}
                label="Customers"
                to="/dashboard/customer"
              />
              <NavLink
                active={pathname === "/dashboard/calender"}
                icon={<Calendar className="h-5 w-5" />}
                label="Calender"
                to="/dashboard/calender"
              />
              <NavLink
                active={pathname === "/dashboard/knowledge"}
                icon={<Book className="h-5 w-5" />}
                label="Knowledge Base"
                to="/dashboard/knowledge"
              />
              <NavLink
                active={pathname === "/dashboard/users"}
                icon={<Users className="h-5 w-5" />}
                label="Users"
                to="/dashboard/users"
              />
              <NavLink
                active={pathname === "/dashboard/settings"}
                icon={<Settings className="h-5 w-5" />}
                label="Settings"
                to="/dashboard/settings"
              />
            </nav>

            <div className="border-t w-full border-nutral-100 flex items-center gap-4 mt-4 p-6">
              <div className="rounded-full flex-shrink-0 overflow-hidden h-10 w-10 bg-gray-400"></div>
              <div className="flex flex-col">
                <div className="flex w-full justify-between items-center gap-1">
                  <p className="text-nutral-800 justify-between font-medium">
                    {user?.username ?? "Guest"}
                  </p>
                  <Link to="/settings">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12h12l-3 -3"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 15l3 -3"
                      />
                    </svg>
                  </Link>
                </div>
                <p className="text-nutral-600 text-xs">
                  {user?.email ?? "No email"}
                </p>
              </div>
              <button
                className="place-content-end w-full flex"
                type="button"
                onClick={handleLogout}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon text-nutral-700 icon-tabler icon-tabler-logout"
                  width="34"
                  height="34"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M9 12h12l-3 -3" />
                  <path d="M18 15l3 -3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
