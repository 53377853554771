import React, { useState } from 'react';
import { 
  LineChart, 
  Line, 
  BarChart,
  Bar,
  PieChart, 
  Pie,
  Cell,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import { 
  TrendingUp,
  Users,
  DollarSign,
  Activity,
  Award,
  Clock,
  Calendar,
  Brain,
  Target,
  BarChart2,
  ArrowUp,
  ArrowDown,
  Filter
} from 'lucide-react';

// Sample data for various charts
const revenueData = [
  { month: 'Jan', revenue: 180000, projects: 12 },
  { month: 'Feb', revenue: 220000, projects: 15 },
  { month: 'Mar', revenue: 280000, projects: 18 },
  { month: 'Apr', revenue: 250000, projects: 16 },
  { month: 'May', revenue: 300000, projects: 20 },
  { month: 'Jun', revenue: 350000, projects: 22 },
  { month: 'Jul', revenue: 380000, projects: 25 },
  { month: 'Aug', revenue: 420000, projects: 28 },
  { month: 'Sep', revenue: 390000, projects: 26 },
  { month: 'Oct', revenue: 450000, projects: 30 },
  { month: 'Nov', revenue: 480000, projects: 32 },
  { month: 'Dec', revenue: 520000, projects: 35 }
];

const serviceDistribution = [
  { name: 'LLM Integration', value: 35 },
  { name: 'Computer Vision', value: 25 },
  { name: 'MLOps', value: 20 },
  { name: 'Data Analytics', value: 15 },
  { name: 'AI Consulting', value: 5 }
];

const industryProjects = [
  { industry: 'Finance', completed: 45, ongoing: 15 },
  { industry: 'Healthcare', completed: 35, ongoing: 20 },
  { industry: 'Retail', completed: 30, ongoing: 12 },
  { industry: 'Technology', completed: 50, ongoing: 25 },
  { industry: 'Manufacturing', completed: 25, ongoing: 10 },
  { industry: 'Education', completed: 20, ongoing: 8 }
];

const clientSatisfaction = [
  { month: 'Jul', score: 4.5 },
  { month: 'Aug', score: 4.6 },
  { month: 'Sep', score: 4.7 },
  { month: 'Oct', score: 4.8 },
  { month: 'Nov', score: 4.7 },
  { month: 'Dec', score: 4.9 }
];

const COLORS = ['#6366F1', '#8B5CF6', '#EC4899', '#F43F5E', '#F59E0B', '#10B981'];

interface MetricCardProps {
  title: string;
  value: string;
  change: string;
  icon: React.ComponentType<any>;
  trend: 'up' | 'down'; // Define that trend can only be 'up' or 'down'
}

// First, fix the MetricCard component by properly using the interface
const MetricCard: React.FC<MetricCardProps> = ({ title, value, change, icon: Icon, trend }) => (
  <div className="bg-white rounded-xl p-6 shadow-sm">
    <div className="flex items-start justify-between">
      <div>
        <p className="text-gray-500 text-sm">{title}</p>
        <h3 className="text-2xl font-bold mt-1">{value}</h3>
        <div className="{flex items-center mt-2 ${trend === 'up' ? 'text-green-600' : 'text-red-600'}}">
          {trend === 'up' ? 
            <ArrowUp className="w-4 h-4 mr-1" /> : 
            <ArrowDown className="w-4 h-4 mr-1" />
          }
          <span className="text-sm font-medium">{change}</span>
        </div>
      </div>
      <div className="p-3 bg-indigo-50 rounded-lg">
        <Icon className="w-6 h-6 text-indigo-600" />
      </div>
    </div>
  </div>
);

function AnalyticsPage() {
  const [timeFrame, setTimeFrame] = useState('year');


  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Analytics Dashboard</h1>
              <p className="text-gray-500 mt-1">Track your AI consultancy performance</p>
            </div>
            <div className="flex items-center space-x-3">
              <select
                className="bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 text-sm"
                value={timeFrame}
                onChange={(e) => setTimeFrame(e.target.value)}
              >
                <option value="month">This Month</option>
                <option value="quarter">This Quarter</option>
                <option value="year">This Year</option>
              </select>
              <button className="flex items-center px-4 py-2 bg-gray-50 rounded-lg text-sm">
                <Filter className="w-4 h-4 mr-2" />
                Filters
              </button>
            </div>
          </div>
        </div>

        {/* Key Metrics */}
        <div className="grid grid-cols-4 gap-6">
          <MetricCard
            title="Total Revenue"
            value="$3.85M"
            change="+12.5% from last year"
            icon={DollarSign}
            trend="up" />
          <MetricCard
            title="Active Projects"
            value="35"
            change="+8 from last month"
            icon={Activity}
            trend="up" />
          <MetricCard
            title="Client Satisfaction"
            value="4.9/5"
            change="+0.2 from last quarter"
            icon={Award}
            trend="up" />
          <MetricCard
            title="Time to Completion"
            value="45 days"
            change="-5 days from average"
            icon={Clock}
            trend="down" />
        </div>

        {/* Charts Row 1 */}
        <div className="grid grid-cols-2 gap-6">
          {/* Revenue Trend */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Revenue & Projects</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="revenue"
                    stroke="#6366F1"
                    strokeWidth={2}
                    name="Revenue ($)" />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="projects"
                    stroke="#EC4899"
                    strokeWidth={2}
                    name="Projects" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Service Distribution */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Service Distribution</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={serviceDistribution}
                    cx="50%"
                    cy="50%"
                    innerRadius={80}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {serviceDistribution.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Charts Row 2 */}
        <div className="grid grid-cols-2 gap-6">
          {/* Industry Projects */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Projects by Industry</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={industryProjects}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="industry" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="completed" fill="#6366F1" name="Completed" />
                  <Bar dataKey="ongoing" fill="#EC4899" name="Ongoing" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Client Satisfaction */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Client Satisfaction Trend</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={clientSatisfaction}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis domain={[3, 5]} />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="score"
                    stroke="#10B981"
                    strokeWidth={2}
                    name="Satisfaction Score" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Additional Metrics */}
        <div className="grid grid-cols-3 gap-6">
          {/* Top Performing Services */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Top Services</h3>
            <div className="space-y-4">
              {serviceDistribution.slice(0, 3).map((service, index) => (
                <div key={service.name} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-2 h-2 rounded-full" style={{ backgroundColor: COLORS[index] }} />
                    <span className="ml-2 text-sm">{service.name}</span>
                  </div>
                  <span className="text-sm font-medium">{service.value}%</span>
                </div>
              ))}
            </div>
          </div>

          {/* Recent Achievements */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Recent Milestones</h3>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <div className="p-2 bg-green-100 rounded-lg">
                  <Award className="w-4 h-4 text-green-600" />
                </div>
                <div>
                  <p className="text-sm font-medium">500th Project Completed</p>
                  <p className="text-xs text-gray-500">December 2024</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <div className="p-2 bg-blue-100 rounded-lg">
                  <Users className="w-4 h-4 text-blue-600" />
                </div>
                <div>
                  <p className="text-sm font-medium">100th Enterprise Client</p>
                  <p className="text-xs text-gray-500">November 2024</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <div className="p-2 bg-purple-100 rounded-lg">
                  <Target className="w-4 h-4 text-purple-600" />
                </div>
                <div>
                  <p className="text-sm font-medium">95% Client Retention</p>
                  <p className="text-xs text-gray-500">Q4 2024</p>
                </div>
              </div>
            </div>
          </div>

          {/* Quick Insights */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-semibold mb-4">Quick Insights</h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <span className="text-sm">Average Project Value</span>
                <span className="text-sm font-medium">$125,000</span>
              </div>
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <span className="text-sm">Team Utilization</span>
                <span className="text-sm font-medium">87%</span>
              </div>
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <span className="text-sm">Client Growth Rate</span>
                <span className="text-sm font-medium">+25%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsPage;
