import React, { useState } from 'react';
import { 
  Users,
  Building2,
  Mail,
  Phone,
  Globe,
  Calendar,
  DollarSign,
  Activity,
  Search
} from 'lucide-react';

interface Customer {
  id: string;
  name: string;
  company: string;
  email: string;
  phone: string;
  website: string;
  industry: string;
  status: 'Active' | 'Pending' | 'Completed';
  projectCount: number;
  totalSpent: number;
  startDate: string;
  lastActivity: string;
  aiServices: string[];
  logo: string;
  teamSize: string;
  activeProjects: {
    name: string;
    type: string;
    progress: number;
  }[];
}

const CUSTOMERS: Customer[] = [
  {
    id: '1',
    name: 'TechCorp Solutions',
    company: 'TechCorp International',
    email: 'contact@techcorp.com',
    phone: '+1 (555) 123-4567',
    website: 'www.techcorp.com',
    industry: 'Enterprise Software',
    status: 'Active',
    projectCount: 5,
    totalSpent: 250000,
    startDate: '2023-06-15',
    lastActivity: '2024-12-10',
    aiServices: ['LLM Integration', 'MLOps', 'Custom Model Training'],
    logo: '/api/placeholder/48/48',
    teamSize: '1000+',
    activeProjects: [
      { name: 'LLM Implementation', type: 'Integration', progress: 75 },
      { name: 'MLOps Pipeline', type: 'Infrastructure', progress: 60 }
    ]
  },
  {
    id: '2',
    name: 'FinTech Innovations',
    company: 'FinTech Global Ltd',
    email: 'ai@fintech.com',
    phone: '+1 (555) 234-5678',
    website: 'www.fintech.com',
    industry: 'Financial Services',
    status: 'Active',
    projectCount: 3,
    totalSpent: 180000,
    startDate: '2024-01-20',
    lastActivity: '2024-12-11',
    aiServices: ['Predictive Analytics', 'NLP', 'Risk Assessment'],
    logo: '/api/placeholder/48/48',
    teamSize: '500-1000',
    activeProjects: [
      { name: 'Risk Analysis Model', type: 'ML Model', progress: 85 }
    ]
  },
  {
    id: '3',
    name: 'HealthAI Systems',
    company: 'HealthTech Solutions',
    email: 'projects@healthai.com',
    phone: '+1 (555) 345-6789',
    website: 'www.healthai.com',
    industry: 'Healthcare',
    status: 'Active',
    projectCount: 4,
    totalSpent: 320000,
    startDate: '2023-09-10',
    lastActivity: '2024-12-09',
    aiServices: ['Medical Imaging AI', 'Patient Analytics', 'Diagnostic ML'],
    logo: '/api/placeholder/48/48',
    teamSize: '100-500',
    activeProjects: [
      { name: 'Diagnostic AI', type: 'ML Model', progress: 90 },
      { name: 'Patient Analytics', type: 'Data Analysis', progress: 45 }
    ]
  },
  {
    id: '4',
    name: 'EduTech Plus',
    company: 'Education Technologies Inc',
    email: 'ai.team@edutech.com',
    phone: '+1 (555) 456-7890',
    website: 'www.edutech.com',
    industry: 'Education',
    status: 'Pending',
    projectCount: 2,
    totalSpent: 150000,
    startDate: '2024-03-01',
    lastActivity: '2024-12-08',
    aiServices: ['Learning Analytics', 'Content Generation'],
    logo: '/api/placeholder/48/48',
    teamSize: '50-100',
    activeProjects: [
      { name: 'Learning Path AI', type: 'Algorithm', progress: 30 }
    ]
  },
  {
    id: '5',
    name: 'RetailAI Corp',
    company: 'Smart Retail Solutions',
    email: 'tech@retailai.com',
    phone: '+1 (555) 567-8901',
    website: 'www.retailai.com',
    industry: 'Retail',
    status: 'Active',
    projectCount: 6,
    totalSpent: 420000,
    startDate: '2023-04-15',
    lastActivity: '2024-12-11',
    aiServices: ['Inventory Prediction', 'Customer Analytics', 'Computer Vision'],
    logo: '/api/placeholder/48/48',
    teamSize: '1000+',
    activeProjects: [
      { name: 'Stock Prediction', type: 'ML Model', progress: 95 },
      { name: 'Customer Insights', type: 'Analytics', progress: 70 }
    ]
  },
  {
    id: '6',
    name: 'SmartFactory',
    company: 'Industrial AI Ltd',
    email: 'operations@smartfactory.com',
    phone: '+1 (555) 678-9012',
    website: 'www.smartfactory.com',
    industry: 'Manufacturing',
    status: 'Completed',
    projectCount: 3,
    totalSpent: 280000,
    startDate: '2023-11-20',
    lastActivity: '2024-12-07',
    aiServices: ['Predictive Maintenance', 'Quality Control AI'],
    logo: '/api/placeholder/48/48',
    teamSize: '500-1000',
    activeProjects: [
      { name: 'Quality AI', type: 'Computer Vision', progress: 100 }
    ]
  },
  {
    id: '7',
    name: 'AIMedia Group',
    company: 'Digital Media Solutions',
    email: 'projects@aimedia.com',
    phone: '+1 (555) 789-0123',
    website: 'www.aimedia.com',
    industry: 'Media & Entertainment',
    status: 'Active',
    projectCount: 4,
    totalSpent: 190000,
    startDate: '2024-02-10',
    lastActivity: '2024-12-11',
    aiServices: ['Content Generation', 'Recommendation Systems'],
    logo: '/api/placeholder/48/48',
    teamSize: '100-500',
    activeProjects: [
      { name: 'Content AI', type: 'NLP', progress: 65 },
      { name: 'RecSys', type: 'ML Model', progress: 40 }
    ]
  },
  {
    id: '8',
    name: 'SecureAI',
    company: 'Cybersecurity Solutions',
    email: 'security@secureai.com',
    phone: '+1 (555) 890-1234',
    website: 'www.secureai.com',
    industry: 'Cybersecurity',
    status: 'Active',
    projectCount: 3,
    totalSpent: 350000,
    startDate: '2023-08-15',
    lastActivity: '2024-12-10',
    aiServices: ['Threat Detection', 'Security Analytics'],
    logo: '/api/placeholder/48/48',
    teamSize: '100-500',
    activeProjects: [
      { name: 'Threat AI', type: 'ML Model', progress: 80 }
    ]
  },
  {
    id: '9',
    name: 'GreenTech AI',
    company: 'Sustainable Solutions',
    email: 'ai@greentech.com',
    phone: '+1 (555) 901-2345',
    website: 'www.greentech.com',
    industry: 'Energy & Sustainability',
    status: 'Pending',
    projectCount: 2,
    totalSpent: 160000,
    startDate: '2024-04-01',
    lastActivity: '2024-12-09',
    aiServices: ['Energy Optimization', 'Sustainability Analytics'],
    logo: '/api/placeholder/48/48',
    teamSize: '50-100',
    activeProjects: [
      { name: 'Energy AI', type: 'Analytics', progress: 25 }
    ]
  },
  {
    id: '10',
    name: 'LogisticsAI',
    company: 'Smart Logistics Corp',
    email: 'tech@logisticsai.com',
    phone: '+1 (555) 012-3456',
    website: 'www.logisticsai.com',
    industry: 'Logistics & Transportation',
    status: 'Active',
    projectCount: 5,
    totalSpent: 290000,
    startDate: '2023-07-20',
    lastActivity: '2024-12-11',
    aiServices: ['Route Optimization', 'Supply Chain AI'],
    logo: '/api/placeholder/48/48',
    teamSize: '500-1000',
    activeProjects: [
      { name: 'Route AI', type: 'Algorithm', progress: 85 },
      { name: 'Supply Chain ML', type: 'ML Model', progress: 55 }
    ]
  }
];

const Customers: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('All');

  // Function to return different status styles
  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Active':
        return 'bg-green-100 text-green-800';
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Completed':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Get unique industries
  const industries = ['All', ...Array.from(new Set(CUSTOMERS.map(c => c.industry)))];

  // Filter customers based on search term and industry
  const filteredCustomers = CUSTOMERS.filter(customer => {
    const matchesSearch = customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         customer.company.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesIndustry = selectedIndustry === 'All' || customer.industry === selectedIndustry;
    return matchesSearch && matchesIndustry;
  });

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="bg-white rounded-xl shadow-lg">
        {/* Header */}
        <div className="p-6 border-b border-gray-200 bg-gradient-to-r from-blue-50 to-purple-50">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-800">Customers</h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center bg-white rounded-lg px-3 py-2 shadow-sm">
                <Search className="w-5 h-5 text-gray-400 mr-2" />
                <input
                  type="text"
                  placeholder="Search customers..."
                  className="outline-none"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <select
                className="bg-white rounded-lg px-3 py-2 shadow-sm outline-none"
                value={selectedIndustry}
                onChange={(e) => setSelectedIndustry(e.target.value)}
              >
                {industries.map(industry => (
                  <option key={industry} value={industry}>{industry}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Stats */}
          <div className="mt-6 grid grid-cols-4 gap-4">
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Total Customers</p>
                  <p className="text-2xl font-bold">{CUSTOMERS.length}</p>
                </div>
                <Users className="w-8 h-8 text-blue-500" />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Active Projects</p>
                  <p className="text-2xl font-bold">
                    {CUSTOMERS.reduce((acc, curr) => acc + curr.activeProjects.length, 0)}
                  </p>
                </div>
                <Activity className="w-8 h-8 text-green-500" />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Total Revenue</p>
                  <p className="text-2xl font-bold">
                    ${(CUSTOMERS.reduce((acc, curr) => acc + curr.totalSpent, 0) / 1000000).toFixed(1)}M
                  </p>
                </div>
                <DollarSign className="w-8 h-8 text-purple-500" />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500">Industries</p>
                  <p className="text-2xl font-bold">{industries.length - 1}</p>
                </div>
                <Building2 className="w-8 h-8 text-orange-500" />
              </div>
            </div>
          </div>
        </div>

        {/* Customer Grid */}
        <div className="p-6">
          <div className="grid grid-cols-1 gap-6">
            {filteredCustomers.map(customer => (
              <div 
                key={customer.id}
                className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start space-x-4">
                    <img
                      src={customer.logo}
                      alt={customer.name}
                      className="w-12 h-12 rounded-lg bg-gray-100"
                    />
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">{customer.name}</h3>
                      <p className="text-sm text-gray-500">{customer.company}</p>
                      <div className="flex items-center mt-2 space-x-2">
                        <span 
                          className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusStyle(customer.status)}`}
                        >
                          {customer.status}
                        </span>
                        <span className="text-xs text-gray-500">
                          Team Size: {customer.teamSize}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-gray-500">Total Spent</p>
                    <p className="text-lg font-bold text-gray-900">
                      ${(customer.totalSpent / 1000).toFixed(1)}k
                    </p>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-3 gap-6">
                  {/* Contact Info */}
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-500">Contact Information</h4>
                    <div className="space-y-2">
                      <div className="flex items-center text-sm">
                        <Mail className="w-4 h-4 mr-2 text-gray-400" />
                        <span>{customer.email}</span>
                      </div>
                      <div className="flex items-center text-sm">
                        <Phone className="w-4 h-4 mr-2 text-gray-400" />
                        <span>{customer.phone}</span>
                      </div>
                      <div className="flex items-center text-sm">
                        <Globe className="w-4 h-4 mr-2 text-gray-400" />
                        <span>{customer.website}</span>
                      </div>
                    </div>
                  </div>

                  {/* AI Services */}
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-500">AI Services</h4>
                    <div className="flex flex-wrap gap-2">
                      {customer.aiServices.map((service, index) => (
                        <span
                          key={index}
                          className="text-xs text-gray-700 px-2 py-1 rounded-full border border-gray-300"
                        >
                          {service}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Active Projects */}
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-500">Active Projects</h4>
                    {customer.activeProjects.map(project => (
                      <div key={project.name} className="space-y-1">
                        <p className="text-xs text-gray-500">{project.name}</p>
                        <div className="relative pt-1">
                          <div className="flex mb-2 items-center justify-between">
                            <span className="text-xs text-gray-500">{project.type}</span>
                          </div>
                          <div className="flex h-2 mb-4 bg-gray-200 rounded-full">
                            <div
                              style={{ width: `${project.progress}%` }}
                              className={`flex h-full rounded-full ${project.progress === 100 ? 'bg-green-400' : 'bg-blue-500'}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
