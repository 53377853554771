export const redirectInNewTab = (url: string) => {
  const win = window.open(url, '_blank')
  if (win !== null) {
      win.focus()
  }
}

export const openInNewWindow = (url: string) => {
  window.open(url, '_blank')
}

export const redirectTo = (url: string) => {
  window.location.assign(url)
}
