import BaseService from "./base.service";

class ApiService extends BaseService {
    async login(credentials: { email: string, password: string}){
        return await this.post('/users/login', credentials)
    }

    async addUser(data: { email: string, password: string}){
        return await this.post('/users/register', data)
    }

    async getCallLogs(){
        return await this.get('/calls/list?limit=100')
    }


     // API Functions
     async sendEmail(
        callId: string,
        recipient: string,
        subject: string,
        content: string,
        cc: string[],
    ) {
        try {
            const response = await this.post(`/follow-up/email`, {
                call_id: callId,
                recipient,
                subject,
                content,
                cc,
                files: [],
            })
            return await response.data
        } catch (error) {
            console.error('Error sending email:', error)
            throw error
        }
    }

    async sendSMS(callId: string, recipient: string, content: string) {
        try {
            const response = await this.post(`/follow-up/sms`, {
                call_id: callId,
                recipient,
                content,
            })
            return await response.data
        } catch (error) {
            console.error('Error sending SMS:', error)
            throw error
        }
    }

    async sendWhatsApp(callId: string, recipient: string, content: string) {
        try {
            const response = await this.post(`/follow-up/whatsapp`, {
                call_id: callId,
                recipient,
                content,
                files: [],
            })
            return await response.data
        } catch (error) {
            console.error('Error sending WhatsApp:', error)
            throw error
        }
    }
}


export default ApiService