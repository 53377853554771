import axios from "axios";
import { setAuthorizationHeader } from "./auth";
import { getItem } from "../services/localStorage.service";

const API_BASE_URL = process.env.REACT_APP_API_DOMAIN || "";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const token = response.headers["Token"];
    // if (token) {
    //     setAuthorizationHeader(token)
    // }
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    const message = error?.response?.data;
    // if (message === 'Invalid token.' || status === 401) {
    //     removeItem('persist:root')
    //     redirectTo('/login')
    //     removeItem('token')
    // }

    return Promise.reject(error);
  }
);

export default axiosInstance;

setAuthorizationHeader(getItem("evelixtoken"));
