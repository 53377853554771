import React, { useState } from 'react'
import {
    ChevronDown,
    ChevronUp,
    Phone,
    PhoneOff,
    Clock,
    FileText,
    User,
} from 'react-feather'
import { ENDED_REASONS } from '../../utils/constants'
import { getStatusStyle } from '../../utils/funcs'

interface CallDetailCardProps {
    call: {
        id: string;
        customer: {
            name: string | null;
            number: string | null;
        };
        startedAt: string | null;
        endedAt: string | null;
        endedReason: keyof typeof ENDED_REASONS | null;
        transcript: string | null;
        type: string | null;
        summary?: string | null;
    };
    additionalCalls?: {
        id: string;
        startedAt?: string | null;
        endedAt?: string | null;
        endedReason?: string | null;
        transcript?: string | null;
    }[];
}


const CallDetailCard: React.FC<CallDetailCardProps> = ({
    call,
    additionalCalls = [],
}) => {
    const [expanded, setExpanded] = useState(false)

    const parseTranscript = (transcript: string | undefined | null) => {
        if (!transcript?.trim()) return []
        const lines = transcript.split('\n')
        return lines
            .filter(line => line.trim())
            .map(line => {
                const [speaker, ...messageParts] = line.split(': ')
                return {
                    speaker: speaker?.trim() || 'Unknown',
                    message: messageParts.join(': ')?.trim() || '',
                }
            })
    }

    const calculateDuration = (start: string | null, end: string | null) => {
        if (!start || !end) return 'N/A'
        try {
            const startDate = new Date(start)
            const endDate = new Date(end)
            const diff = endDate.getTime() - startDate.getTime()
            const minutes = Math.floor(diff / 60000)
            const seconds = Math.floor((diff % 60000) / 1000)
            return `${minutes}:${seconds.toString().padStart(2, '0')}`
        } catch (error) {
            console.error('Error calculating duration:', error)
            return 'N/A'
        }
    }

    const duration = calculateDuration(call.startedAt, call.endedAt)
    const transcript = parseTranscript(call.transcript)

    return (
        <div className="border rounded-lg shadow-sm p-4 bg-white hover:shadow-md transition-all duration-200">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div
                        className={
                            call.startedAt ? 'text-green-500' : 'text-red-500'
                        }>
                        {call.startedAt ? (
                            <Phone className="h-6 w-6" />
                        ) : (
                            <PhoneOff className="h-6 w-6" />
                        )}
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <h3 className="text-lg font-medium text-gray-900">
                                {call.customer.name || 'Unknown'}
                            </h3>
                            {call.type && (
                                <span className="text-sm text-gray-500">
                                    ({call.type})
                                </span>
                            )}
                        </div>
                        <p className="text-sm text-gray-500">
                            {call.customer.number || 'No number'}
                        </p>
                        <div className="flex items-center gap-2 mt-1">
                            <Clock className="h-4 w-4 text-gray-400" />
                            <span className="text-sm text-gray-500">
                                {duration}
                            </span>
                            {call.endedReason && (
                                <span
                                    className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusStyle(call.endedReason as string)}`}>
                                    {ENDED_REASONS[call.endedReason]}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => setExpanded(!expanded)}
                    className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100">
                    {expanded ? (
                        <ChevronUp className="h-5 w-5" />
                    ) : (
                        <ChevronDown className="h-5 w-5" />
                    )}
                </button>
            </div>

            {expanded && (
                <div className="mt-4 space-y-4">
                    {(call.type || call.endedReason) && (
                        <div className="flex items-center gap-2 text-sm text-gray-600">
                            <FileText className="h-5 w-5 text-blue-500" />
                            <span>Call Details</span>
                        </div>
                    )}

                    {call.summary && (
                        <div className="bg-blue-50 border border-blue-200 rounded-lg p-3 text-sm text-blue-700">
                            {call.summary}
                        </div>
                    )}

                    {transcript.length > 0 && (
                        <div className="bg-gray-50 p-4 rounded-lg border">
                            <h4 className="text-md font-medium text-gray-900 mb-3">
                                Transcript:
                            </h4>
                            <div className="space-y-3">
                                {transcript.map((line, index) => (
                                    <div
                                        key={index}
                                        className={`flex items-start gap-3 ${line.speaker.toLowerCase() === 'ai'
                                                ? ''
                                                : 'flex-row-reverse'
                                            }`}>
                                        <div
                                            className={`flex-none w-8 h-8 rounded-full flex items-center justify-center ${line.speaker.toLowerCase() ===
                                                    'ai'
                                                    ? 'bg-blue-100'
                                                    : 'bg-green-100'
                                                }`}>
                                            {line.speaker.toLowerCase() === 'ai'
                                                ? '🤖'
                                                : '👤'}
                                        </div>
                                        <div
                                            className={`${line.speaker.toLowerCase() ===
                                                    'ai'
                                                    ? 'bg-blue-50 text-blue-800'
                                                    : 'bg-green-50 text-green-800'
                                                } px-4 py-2 rounded-lg text-sm max-w-[80%]`}>
                                            {line.message}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {additionalCalls && additionalCalls.length > 0 && (
                        <div className="bg-gray-50 p-4 rounded-lg border">
                            <h4 className="text-md font-medium text-gray-900 mb-2">
                                Related Calls
                            </h4>
                            <div className="space-y-2">
                                {additionalCalls.map(
                                    (additionalCall, index) => (
                                        <div
                                            key={additionalCall.id}
                                            className="flex items-center gap-2 text-sm text-gray-700">
                                            <User className="h-4 w-4 text-gray-400" />
                                            <span className="font-medium">
                                                Call {index + 1}:
                                            </span>
                                            <span>
                                                {additionalCall.endedReason ||
                                                    'N/A'}
                                            </span>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CallDetailCard
