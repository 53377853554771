interface Ticket {
    startedAt: string;
    endedAt: string;
}

export const formatFileSize = (bytes: number): string => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${Math.round(size * 100) / 100} ${units[unitIndex]}`;
};

export const getStatusStyle = (status: string | null): string => {
    if (!status) return 'bg-gray-100 text-gray-700 border-gray-200';

    switch (status.toLowerCase()) {
        case 'customer-did-not-answer':
            return 'bg-yellow-100 text-yellow-700 border-yellow-200';
        case 'customer-busy':
            return 'bg-red-100 text-red-700 border-red-200';
        case 'customer-ended-call':
            return 'bg-blue-100 text-blue-700 border-blue-200';
        case 'assistant-said-end-call-phrase':
            return 'bg-green-100 text-green-700 border-green-200';
        default:
            return 'bg-gray-100 text-gray-700 border-gray-200';
    }
};

export const formatDuration = (ticket: any): string => {
    if (!ticket.startedAt || !ticket.endedAt) return 'N/A';
    const totalSeconds = Math.round(
        (new Date(ticket.endedAt).getTime() - new Date(ticket.startedAt).getTime()) / 1000
    );

    if (totalSeconds >= 60) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}m ${seconds}s`;
    }

    return `${totalSeconds}s`;
};
