import React from 'react';
import { api } from '../pages/Knowledge';
import { Download, Eye, Trash } from 'react-feather';
import { formatFileSize } from '../utils/funcs';
import { Loader } from './ui/Loader';
import { useNotifications } from '../hooks/useNotifications';

// Define the interface for File
interface File {
    id: number;
    filename: string;
    file_type: string;
    file_size: number;
    created_at: string;
    download_url: string;
}

// Define the props for FilesGrid
interface FilesGridProps {
    files: File[];
    fetchFiles: () => Promise<void>;
    setSelectedFile: (file: File) => void;
    loading: boolean;
}

export const FilesGrid: React.FC<FilesGridProps> = ({
    files,
    fetchFiles,
    setSelectedFile,
    loading,
}) => {
    const { successMessage, errorMessage } = useNotifications();

    const handleDelete = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this file?')) return;

        try {
            await api.deleteFile(id);
            await fetchFiles();
            successMessage('File deleted successfully');
        } catch (error) {
            errorMessage(
                error instanceof Error ? error.message : 'Failed to delete file'
            );
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {loading ? (
                <div className="mx-auto col-span-3">
                    <Loader message="Loading files..." />
                </div>
            ) : files.length === 0 ? (
                <div className="col-span-full text-center py-12 text-gray-500">
                    No files uploaded yet
                </div>
            ) : (
                files.map((file: File) => (
                    <div
                        key={file.id}
                        className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 hover:shadow-md transition-shadow"
                    >
                        <div className="flex items-center mb-4">
                            <span className="p-2 bg-gray-100 rounded-lg">
                                {file.file_type.toUpperCase()}
                            </span>
                        </div>

                        <h3 className="font-medium text-gray-900 mb-1 truncate">
                            {file.filename}
                        </h3>

                        <p className="text-sm text-gray-500 mb-4">
                            {formatFileSize(file.file_size)} •{' '}
                            {new Date(file.created_at).toLocaleDateString()}
                        </p>

                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setSelectedFile(file)}
                                className="p-1.5 text-gray-500 hover:text-blue-500 transition-colors"
                                title="Preview"
                            >
                                <Eye className="h-5 w-5" />
                            </button>
                            <a
                                href={file.download_url}
                                target="_blank"
                                className="p-1.5 text-gray-500 hover:text-green-500 transition-colors"
                                title="Download"
                                rel="noreferrer"
                            >
                                <Download className="h-5 w-5" />
                            </a>
                            <button
                                onClick={() => handleDelete(file.id)}
                                className="p-1.5 text-gray-500 hover:text-red-500 transition-colors"
                                title="Delete"
                            >
                                <Trash className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
