import React, { useState, useEffect } from 'react'
import { Download, X } from 'react-feather'
import { FilesGrid } from '../components/FilesGrid'
import { UploadZone } from '../components/KnowledgeBase/UploadZone'
import { formatFileSize } from '../utils/funcs'
import { ApiResponse } from '../types'
import axiosInstance from '../utils/axiosInstance'

export interface FileData {
    id: number
    filename: string
    file_type: string
    file_size: number
    created_at: string
    download_url: string
}

export const api = {
    async listFiles(): Promise<FileData[]> {
        try {
            const response =
                await axiosInstance.get<ApiResponse<FileData[]>>(
                    `/knowledge/list`,
                )
            return response.data.data ?? []
        } catch (error) {
            throw this.handleError(error)
        }
    },

    async uploadFile(
        file: File,
        onProgress: (progress: number) => void,
    ): Promise<FileData> {
        try {
            const formData = new FormData()
            formData.append('file', file)

            const response = await axiosInstance.post<ApiResponse<FileData>>(
                `/knowledge/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: progressEvent => {
                        const progress =
                            (progressEvent.loaded / progressEvent.total!) * 100
                        onProgress(progress)
                    },
                },
            )

            return response.data.data
        } catch (error) {
            throw this.handleError(error)
        }
    },

    async deleteFile(id: number): Promise<void> {
        try {
            await axiosInstance.delete(`/knowledge/${id}`)
        } catch (error) {
            throw this.handleError(error)
        }
    },

    handleError(error: any): Error {
        // if (axiosInstance.isAxiosError(error)) {
        //     return new Error(error.response?.data?.message || error.message)
        // }
        return new Error(
            (error.response?.data?.message || error.message) ??
                'An unexpected error occurred',
        )
    },
}

export const KnowledgeBase: React.FC = () => {
    const [files, setFiles] = useState<FileData[]>([])
    const [selectedFile, setSelectedFile] = useState<FileData | null>(null)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchFiles()
    }, [])

    const fetchFiles = async () => {
        try {
            setLoading(true)
            const fetchedFiles = await api.listFiles()
            setFiles(fetchedFiles)
        } catch (error) {
            console.error(
                error instanceof Error
                    ? error.message
                    : 'Failed to fetch files',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="container mx-auto ">
            {/* Upload Zone */}
            <UploadZone fetchFiles={fetchFiles} />

            {/* File Grid */}
            <FilesGrid
                files={files}
                fetchFiles={fetchFiles}
                setSelectedFile={setSelectedFile}
                loading={loading}
            />

            {/* Preview Modal */}
            {selectedFile && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg w-full max-w-3xl">
                        <div className="flex items-center justify-between p-4 border-b">
                            <div>
                                <h3 className="text-lg font-medium">
                                    {selectedFile.filename}
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {formatFileSize(selectedFile.file_size)} •{' '}
                                    {selectedFile.file_type.toUpperCase()}
                                </p>
                            </div>
                            <button
                                onClick={() => setSelectedFile(null)}
                                className="text-gray-400 hover:text-gray-500">
                                <X className="h-6 w-6" />
                            </button>
                        </div>

                        <div className="p-4">
                            <p className="text-sm text-gray-500 mb-4">
                                Created:{' '}
                                {new Date(
                                    selectedFile.created_at,
                                ).toLocaleString()}
                            </p>

                            <a
                                href={selectedFile.download_url}
                                target="_blank"
                                className="w-full bg-blue-500 text-white rounded-lg px-4 py-2 flex items-center justify-center hover:bg-blue-600 transition-colors"
                                rel="noreferrer">
                                <Download className="h-5 w-5 mr-2" />
                                Download File
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
