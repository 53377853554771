import DashboardLayout from '../components/layouts/DashboardLayout';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Conversations from '../pages/Conversations';
import Analytics from '../pages/Analytics';
import { Settings } from '../pages/Settings';
import { KnowledgeBase } from '../pages/Knowledge';
import Calender from '../pages/Calender';
import Customers from '../pages/Customers';
import CallLogs from '../pages/CallLogs';
import Tickets from '../pages/Tickets';
import { UsersPage } from '../pages/Users';
import { Navigate } from 'react-router-dom';
import ConversationDetails from '../components/conversation/ConversationDetails';
import TicketDetails from '../components/CallCard/TicketDetail';

export const routes = (isAuth: boolean) => [
  {
    path: '/',
    element: isAuth ? <Navigate to="/dashboard" /> : <Navigate to="/login" />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/dashboard',
    element: isAuth ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/dashboard/calllogs',
        element: <CallLogs />,
      },
      {
        path: '/dashboard/tickets',
        element: <Tickets />,
      },
      {
        path: '/dashboard/tickets/:id',
        element: <TicketDetails />,
      },
      {
        path: '/dashboard/conversations',
        element: <Conversations />,
      },
      {
        path: '/dashboard/conversations/:id',
        element: <ConversationDetails />,
      },
      {
        path: '/dashboard/customer',
        element: <Customers />,
      },
      {
        path: '/dashboard/analytics',
        element: <Analytics />,
      },
      {
        path: '/dashboard/calender',
        element: <Calender />,
      },
      {
        path: '/dashboard/settings',
        element: <Settings />,
      },
      {
        path: '/dashboard/knowledge',
        element: <KnowledgeBase />,
      },
      {
        path: '/dashboard/users',
        element: <UsersPage />,
      },
    ],
  },
];