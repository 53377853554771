import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ArrowLeft,
  Send,
  Archive,
  Share2,
  Download,
  Trash2,
  MoreVertical
} from 'react-feather';
import { Button, IconButton } from '../forms/Button';
import { Input } from '../forms/Input';
import { Alert } from '../ui/Alert';
import ApiService from '../../services/api.service';
import { ConversationResponse, Message } from '../../types';

const service = new ApiService();

const ConversationDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [conversation, setConversation] = useState<ConversationResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // useEffect(() => {
  //   if (id) {
  //     fetchConversation();
  //   }
  // }, [id]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation?.messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // const fetchConversation = async () => {
  //   try {
  //     const response = await service.getConversationById(id!);
  //     setConversation(response.data);
  //     setError(null);
  //   } catch (error) {
  //     setError('Failed to load conversation');
  //     console.error('Error fetching conversation:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleSendMessage = async () => {
  //   if (!message.trim() || !conversation) return;

  //   const tempMessage: Message = {
  //     id: Date.now().toString(),
  //     role: 'user',
  //     content: message,
  //     timestamp: new Date().toISOString()
  //   };

  //   setConversation({
  //     ...conversation,
  //     messages: [...conversation.messages, tempMessage]
  //   });
  //   setMessage('');
  //   setSending(true);

  //   try {
  //     const response = await service.createConversation(message); // Update with actual API call
  //     setConversation(response.data);
  //   } catch (error) {
  //     setError('Failed to send message');
  //     console.error('Error sending message:', error);
  //   } finally {
  //     setSending(false);
  //   }
  // };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!conversation) {
    return (
      <div className="p-4">
        <Alert type="error" message="Conversation not found" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/dashboard/conversations')}
            className="text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <div>
            <h2 className="text-lg font-medium text-gray-900">{conversation.title}</h2>
            <p className="text-sm text-gray-500">
              {conversation.messages.length} messages
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <IconButton
            icon={<Share2 className="h-4 w-4" />}
            variant="outline"
            onClick={() => {/* Add share handling */}}
          />
          <IconButton
            icon={<Download className="h-4 w-4" />}
            variant="outline"
            onClick={() => {/* Add export handling */}}
          />
          <IconButton
            icon={<Archive className="h-4 w-4" />}
            variant="outline"
            onClick={() => {/* Add archive handling */}}
          />
          <IconButton
            icon={<Trash2 className="h-4 w-4" />}
            variant="outline"
            onClick={() => {/* Add delete handling */}}
          />
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {conversation.messages.map((msg, index) => (
          <div
            key={msg.id}
            className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[70%] rounded-lg px-4 py-2 ${
                msg.role === 'user'
                  ? 'bg-primary text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}
            >
              <p className="whitespace-pre-wrap">{msg.content}</p>
              <p className="text-xs mt-1 opacity-70">
                {new Date(msg.timestamp).toLocaleTimeString()}
              </p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input */}
      <div className="p-4 border-t bg-white">
        {error && (
          <div className="mb-4">
            <Alert type="error" message={error} onClose={() => setError(null)} />
          </div>
        )}
        <div className="flex gap-2">
          {/* <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
            disabled={sending}
          /> */}
          <Button
            icon={<Send className="h-4 w-4" />}
            // onClick={handleSendMessage}
            loading={sending}
            disabled={!message.trim()}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConversationDetails;