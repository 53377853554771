import React from 'react'
import { Mail, MessageSquare, MessageCircle } from 'react-feather'
import { Action } from '../../types'

interface ActionButtonProps {
    type: Action['action_type']
    onClick: () => void
    disabled?: boolean
}

export const ActionButton: React.FC<ActionButtonProps> = ({
    type,
    onClick,
    disabled = false,
}) => {
    const getActionStyle = () => {
        switch (type) {
            case 'send_email':
                return 'bg-blue-100 text-blue-800 hover:bg-blue-200'
            case 'send_whatsapp':
                return 'bg-green-100 text-green-800 hover:bg-green-200'
            case 'send_sms':
                return 'bg-purple-100 text-purple-800 hover:bg-purple-200'
            default:
                return 'bg-gray-100 text-gray-800 hover:bg-gray-200'
        }
    }

    const getActionIcon = () => {
        switch (type) {
            case 'send_email':
                return <Mail className="w-4 h-4" />
            case 'send_whatsapp':
                return <MessageSquare className="w-4 h-4" />
            case 'send_sms':
                return <MessageCircle className="w-4 h-4" />
            default:
                return null
        }
    }

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`px-4 py-2 rounded-lg flex items-center gap-2 transition-colors ${getActionStyle()} ${
                disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}>
            {getActionIcon()}
            <span className="capitalize">{type.replace('_', ' ')}</span>
        </button>
    )
}
