import { Input } from '../components/forms/Input'
import { Loader } from '../components/ui/Loader'
import { Modal } from '../components/ui/Modal'
import { useNotifications } from '../hooks/useNotifications'
import React, { useEffect, useState } from 'react'
import { Plus, Trash2 } from 'react-feather'
import axiosInstance from '../utils/axiosInstance'
import ApiService from '../services/api.service'

interface User {
  id: number
  username: string | null
  email: string | null
}

const service = new ApiService()

export const UsersPage = () => {
  const [users, setUsers] = useState<User[]>([])
  const [showModal, setShowModal] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [state, setState] = useState({
    username: '',
    email: '',
    password: '',
  })
  const [loading, setLoading] = useState(false)

  const { successMessage, errorMessage } = useNotifications()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState({
      ...state,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    setLoading(true)

    try {
      await service.addUser(state)
      setState({
        username: '',
        email: '',
        password: '',
      })
      successMessage('User added successfully')
    } catch (error: any) {
      errorMessage(error?.response?.data?.detail[0]?.msg)
    } finally {
      setLoading(false)
      setShowModal(false)
    }
  }

  useEffect(() => {
    const fetchData = () => {
      setFetching(true)
      axiosInstance
        .get('/users/list')
        .then(response => {
          setUsers(response.data.data)
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            errorMessage(error?.response?.data.detail)
          } else {
            errorMessage(error?.response?.data?.detail?.message)
          }
        })
        .finally(() => {
          setFetching(false)
        })
    }

    fetchData()
  }, [])

  const handleDelete = async (email: string) => {
    setLoading(true)

    try {
      await axiosInstance.delete(`/users/delete/${email}`)
      setUsers(users.filter(user => user.email !== email))
      successMessage('User deleted successfully')
    } catch (error: any) {
      errorMessage(error?.response?.data?.detail || 'Error deleting user')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center w-full">
        <h1 className="font-HelveticaB text-2xl text-zinc-700">
          
        </h1>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="text-xs md:text-base px-3.5 py-2 flex items-center gap-2 disabled:opacity-50 rounded-md shadow-sm font-Helvetica tracking-wide border text-white bg-blue-500">
          <Plus className="h-4 w-4" />
          <span>Add</span>
        </button>
      </div>
      <div className="relative flex flex-col w-full overflow-scroll text-gray-700 bg-white shadow  bg-clip-border rounded-lg">
        <table className="w-full text-left table-auto border rounded-lg min-w-max">
          <thead>
            <tr className="border border-zinc-100">
              <th className="p-4 bg-gray-50">
                <p className="block text-sm font-normal text-gray-700">
                  Username
                </p>
              </th>
              <th className="p-4 bg-gray-50">
                <p className="block text-sm font-normal text-gray-700">
                  Email
                </p>
              </th>
              <th className="p-4 text-center bg-gray-50">
                <p className="block text-sm font-normal text-gray-700">
                  Actions
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 && fetching ? (
              <tr>
                <td colSpan={3}>
                  <Loader message="Loading users..." />
                </td>
              </tr>
            ) : users.length > 0 ? (
              users.map(user => (
                <tr key={user.id} className="border">
                  <td className="p-4">
                    <p className="text-sm text-blue-gray-900">
                      {user.username
                        ? user.username
                        : '-'}
                    </p>
                  </td>
                  <td className="p-4">
                    <p className="text-sm text-blue-gray-900">
                      {user.email}
                    </p>
                  </td>
                  <td className="p-4 text-center">
                    <button
                      onClick={() =>
                        handleDelete(user.email!)
                      }
                      className="text-red-600 hover:text-red-800">
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={3}
                  className="p-4 text-center text-gray-500">
                  No users found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        showModal={showModal}
        handleClickPrimary={handleSubmit}
        title="Add User"
        footerButton="Save"
        width="w-full md:w-1/3"
        setShowModal={setShowModal}>
        <div className="px-6 py-4">
          <div className="flex flex-col gap-4">
            <Input
              className="bg-nutral-50"
              labelStyle="text-nutral-700"
              label="Username"
              name="username"
              value={state.username}
              onChange={handleChange}
            />
            <Input
              className="bg-nutral-50"
              labelStyle="text-nutral-700"
              label="Email"
              name="email"
              value={state.email}
              onChange={handleChange}
            />
            <Input
              className="bg-nutral-50"
              labelStyle="text-nutral-700"
              label="Password"
              type="password"
              name="password"
              value={state.password}
              onChange={handleChange}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
