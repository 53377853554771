import React from 'react';

const ConversationPage: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold text-gray-900">Conversation</h2>
    </div>
  );
};

export default ConversationPage;
