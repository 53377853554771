import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Clock, FileText, ArrowLeft, AlertCircle } from 'react-feather'
import { Action } from '../../types'
import { ENDED_REASONS } from '../../utils/constants'
import { formatDuration, getStatusStyle } from '../../utils/funcs'
import { ActionButton } from './ActionButtons'
import { ActionContent } from './ActionContent'

// Interfaces (same as before)
interface Customer {
    name: string
    number: string
    email?: string
}

interface Ticket {
    id: string
    type: string
    startedAt: string | null
    endedAt: string | null
    createdAt: string
    summary: string | null
    transcript: string | null
    customer: Customer
    endedReason: string | null
    actions: {
        actions: Action[]
    }
}

// Components

const TicketDetails: React.FC = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [selectedAction, setSelectedAction] = useState<Action | null>(null)
    const ticket = state?.ticket as Ticket

    if (!ticket) {
        return (
            <div className="flex items-center justify-center h-full rounded-lg bg-gray-50">
                <div className="text-center">
                    <AlertCircle className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                        Ticket not found
                    </h3>
                    <p className="text-gray-500">
                        The ticket you're looking for doesn't exist or you don't
                        have access.
                    </p>
                </div>
            </div>
        )
    }

    const handleActionClick = (action: Action) => {
        setSelectedAction(
            selectedAction?.action_type === action.action_type ? null : action,
        )
    }

    const handleActionSubmit = () => {
        // Reset selected action after submission
        setSelectedAction(null)
    }

    return (
        <div className="flex h-[100%] border rounded-xl overflow-hidden bg-gray-50">
            {/* Left Panel */}
            <div className="w-80 border-r border-gray-200 bg-white p-6">
                <div className="space-y-5">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center gap-2 text-gray-600 hover:text-gray-900">
                        <ArrowLeft className="h-5 w-5" />
                        <span>Back to Tickets</span>
                    </button>

                    <div>
                        <h2 className="text-xl font-semibold text-gray-900 mb-1">
                            Ticket #{ticket.id.slice(0, 8)}
                        </h2>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                            <Clock className="h-4 w-4" />
                            <span>
                                {new Date(ticket.createdAt).toLocaleString()}
                            </span>
                        </div>
                    </div>

                    <div>
                        <h3 className="text-sm font-medium text-gray-500 mb-3">
                            Customer
                        </h3>
                        <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                            <div className="h-10 w-10 rounded-full bg-blue-500 flex items-center justify-center text-white">
                                {ticket.customer.name.charAt(0).toUpperCase()}
                            </div>
                            <div>
                                <div className="font-medium">
                                    {ticket.customer.name}
                                </div>
                                <div className="text-sm text-gray-500">
                                    {ticket.customer.number}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h3 className="text-sm font-medium text-gray-500 mb-2">
                            Call Status
                        </h3>
                        <div className="flex items-center gap-2">
                            <span
                                className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusStyle(ticket.endedReason as string)}`}>
                                {ENDED_REASONS[ticket.endedReason as string] }
                            </span>
                            <span className="text-sm text-gray-500">
                                ({formatDuration(ticket )})
                            </span>
                        </div>
                    </div>

                    {ticket.summary && (
                        <>
                            <h3 className="text-sm font-medium text-gray-500">
                                Summary
                            </h3>
                            <p className="text-sm text-gray-700 bg-gray-50 rounded-lg p-3">
                                {ticket.summary}
                            </p>
                        </>
                    )}
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col overflow-y-auto">
                <div className="p-6">
                    <div className="space-y-6">
                        {/* Transcript Section */}
                        {ticket.transcript && (
                            <div className="bg-white border rounded-xl shadow-sm p-6">
                                <div className="flex items-center gap-2 mb-4">
                                    <FileText className="h-5 w-5 text-gray-500" />
                                    <h3 className="font-medium">
                                        Call Transcript
                                    </h3>
                                </div>
                                <pre className="text-sm text-gray-700 whitespace-pre-wrap font-sans bg-gray-50 rounded-lg p-4">
                                    {ticket.transcript}
                                </pre>
                            </div>
                        )}

                        {/* Actions Section */}
                        <div className="bg-white border rounded-xl shadow-sm p-6">
                            <h3 className="font-medium mb-4">
                                Available Actions
                            </h3>
                            {ticket?.actions?.actions &&
                            ticket.actions.actions.length > 0 ? (
                                <>
                                    <div className="flex flex-wrap gap-3">
                                        {ticket.actions.actions.map(
                                            (action, idx) => (
                                                <ActionButton
                                                    key={idx}
                                                    type={action.action_type}
                                                    onClick={() =>
                                                        handleActionClick(
                                                            action,
                                                        )
                                                    }
                                                />
                                            ),
                                        )}
                                    </div>

                                    {selectedAction && (
                                        <ActionContent
                                            action={selectedAction}
                                            callId={ticket.id}
                                            onSubmit={handleActionSubmit}
                                        />
                                    )}
                                </>
                            ) : (
                                <p className="text-sm text-gray-500">
                                    No actions available for this ticket.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketDetails
