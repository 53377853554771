import React, { useState } from 'react';
import { 
  ChevronLeft, 
  ChevronRight,
  Clock,
  Users,
  Video,
  Briefcase,
  Code,
  Brain,
  LineChart,
  MessageSquare,
  Laptop,
  Radar
} from 'lucide-react';

interface Appointment {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  title: string;
  client: string;
  type: 'consultation' | 'demo' | 'workshop' | 'review' | 'planning' | 
        'implementation' | 'training' | 'support' | 'deployment' | 'data-analysis';
  description: string;
  attendees: number;
  virtual?: boolean;
}

const APPOINTMENTS: Appointment[] = [
  // Week before current (Dec 5-11)
  {
    id: '1',
    date: '2024-12-05',
    startTime: '09:00',
    endTime: '10:30',
    title: 'AI Strategy Planning',
    client: 'GlobalTech Solutions',
    type: 'planning',
    description: 'Annual AI implementation strategy review',
    attendees: 6,
    virtual: true
  },
  {
    id: '2',
    date: '2024-12-06',
    startTime: '11:00',
    endTime: '12:30',
    title: 'NLP Model Deployment',
    client: 'TextAnalytics Corp',
    type: 'deployment',
    description: 'Production deployment of sentiment analysis model',
    attendees: 4
  },
  {
    id: '3',
    date: '2024-12-07',
    startTime: '14:00',
    endTime: '15:30',
    title: 'Data Pipeline Review',
    client: 'DataStream Inc',
    type: 'data-analysis',
    description: 'Optimization of ML data pipelines',
    attendees: 5
  },
  {
    id: '4',
    date: '2024-12-11',
    startTime: '10:00',
    endTime: '11:30',
    title: 'AI Model Training',
    client: 'SmartSys Ltd',
    type: 'training',
    description: 'Custom model training workshop',
    attendees: 8,
    virtual: true
  },

  // Current Week (Dec 12-18)
  {
    id: '5',
    date: '2024-12-12',
    startTime: '09:00',
    endTime: '10:00',
    title: 'LLM Integration Demo',
    client: 'InnovateAI',
    type: 'demo',
    description: 'Demonstration of GPT model integration',
    attendees: 10,
    virtual: true
  },
  {
    id: '6',
    date: '2024-12-12',
    startTime: '14:00',
    endTime: '15:30',
    title: 'Support Escalation',
    client: 'TechForward',
    type: 'support',
    description: 'Critical support for production models',
    attendees: 4
  },
  {
    id: '7',
    date: '2024-12-13',
    startTime: '11:00',
    endTime: '12:30',
    title: 'MLOps Workshop',
    client: 'CloudNine Solutions',
    type: 'workshop',
    description: 'Best practices for ML operations',
    attendees: 15,
    virtual: true
  },
  {
    id: '8',
    date: '2024-12-14',
    startTime: '09:30',
    endTime: '11:00',
    title: 'Computer Vision Review',
    client: 'VisionTech',
    type: 'review',
    description: 'Quarterly review of CV models',
    attendees: 6
  },
  {
    id: '9',
    date: '2024-12-15',
    startTime: '13:00',
    endTime: '14:30',
    title: 'API Implementation',
    client: 'WebServices Co',
    type: 'implementation',
    description: 'Integration of ML models via API',
    attendees: 5
  },
  {
    id: '10',
    date: '2024-12-16',
    startTime: '10:00',
    endTime: '11:30',
    title: 'Initial Consultation',
    client: 'NewTech Startup',
    type: 'consultation',
    description: 'AI feasibility assessment',
    attendees: 4,
    virtual: true
  },

  // Week after (Dec 19-25)
  {
    id: '11',
    date: '2024-12-19',
    startTime: '09:00',
    endTime: '10:30',
    title: 'Model Deployment',
    client: 'FinTech Solutions',
    type: 'deployment',
    description: 'Production deployment planning',
    attendees: 7
  },
  {
    id: '12',
    date: '2024-12-20',
    startTime: '13:00',
    endTime: '14:30',
    title: 'Data Analysis Workshop',
    client: 'Analytics Pro',
    type: 'data-analysis',
    description: 'Advanced data preprocessing techniques',
    attendees: 12,
    virtual: true
  },
  {
    id: '13',
    date: '2024-12-21',
    startTime: '11:00',
    endTime: '12:30',
    title: 'Support Session',
    client: 'TechCorp',
    type: 'support',
    description: 'Monthly support and maintenance',
    attendees: 3
  }
];

const generateDateRange = () => {
  const dates = [];
  const today = new Date('2024-12-12');
  const start = new Date(today);
  start.setDate(today.getDate() - 7);
  
  for (let i = 0; i < 15; i++) {
    const date = new Date(start);
    date.setDate(start.getDate() + i);
    dates.push(date.toISOString().split('T')[0]);
  }
  return dates;
};

const DATES = generateDateRange();


const TIME_SLOTS = Array.from({ length: 9 }, (_, i) => {
  const hour = i + 8;
  return `${hour.toString().padStart(2, '0')}:00`;
});

const Calender: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  
  const getAppointmentStyle = (type: string) => {
    const styles = {
      consultation: {
        bg: 'bg-purple-100',
        border: 'border-l-4 border-purple-500',
        text: 'text-purple-800',
        icon: <MessageSquare className="w-4 h-4" />
      },
      demo: {
        bg: 'bg-blue-100',
        border: 'border-l-4 border-blue-500',
        text: 'text-blue-800',
        icon: <Laptop className="w-4 h-4" />
      },
      workshop: {
        bg: 'bg-green-100',
        border: 'border-l-4 border-green-500',
        text: 'text-green-800',
        icon: <Users className="w-4 h-4" />
      },
      review: {
        bg: 'bg-yellow-100',
        border: 'border-l-4 border-yellow-500',
        text: 'text-yellow-800',
        icon: <LineChart className="w-4 h-4" />
      },
      planning: {
        bg: 'bg-orange-100',
        border: 'border-l-4 border-orange-500',
        text: 'text-orange-800',
        icon: <Briefcase className="w-4 h-4" />
      },
      implementation: {
        bg: 'bg-cyan-100',
        border: 'border-l-4 border-cyan-500',
        text: 'text-cyan-800',
        icon: <Code className="w-4 h-4" />
      },
      training: {
        bg: 'bg-indigo-100',
        border: 'border-l-4 border-indigo-500',
        text: 'text-indigo-800',
        icon: <Brain className="w-4 h-4" />
      },
      support: {
        bg: 'bg-red-100',
        border: 'border-l-4 border-red-500',
        text: 'text-red-800',
        icon: <Radar className="w-4 h-4" />
      }
    };
    return styles[type as keyof typeof styles] || styles.consultation;
  };

  const getWeekDates = () => {
    const dates = [];
    const start = new Date(currentDate);
    start.setDate(start.getDate() - start.getDay() + 1);

    for (let i = 0; i < 5; i++) {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const getAppointmentsForDateTime = (date: Date, time: string): Appointment | undefined => {
    const dateStr = date.toISOString().split('T')[0];
    return APPOINTMENTS.find(
      app => app.date === dateStr && app.startTime === time
    );
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short'
    }).format(date);
  };

  const navigateWeek = (direction: 'prev' | 'next') => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
    setCurrentDate(newDate);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="bg-white rounded-xl shadow-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200 bg-gradient-to-r from-blue-50 to-purple-50">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-gray-800">AI Consultancy Calendar</h1>
            <div className="flex items-center space-x-4">
              <button 
                className="p-2 hover:bg-white/50 rounded-lg transition-colors"
                onClick={() => navigateWeek('prev')}
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <h2 className="text-lg font-semibold">
                Week of {currentDate.toLocaleDateString('default', { 
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </h2>
              <button 
                className="p-2 hover:bg-white/50 rounded-lg transition-colors"
                onClick={() => navigateWeek('next')}
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Legend */}
          <div className="mt-4 flex flex-wrap gap-4">
            {Object.entries({
              consultation: 'Consultations',
              demo: 'Demos',
              workshop: 'Workshops',
              review: 'Reviews',
              planning: 'Planning',
              implementation: 'Implementation',
              training: 'Training',
              support: 'Support'
            }).map(([type, label]) => {
              const style = getAppointmentStyle(type);
              return (
                <div 
                  key={type}
                  className={`flex items-center px-3 py-1 rounded-full ${style.bg} ${style.text}`}
                >
                  {style.icon}
                  <span className="ml-2 text-sm">{label}</span>
                </div>
              );
            })}
          </div>
        </div>

        {/* Calendar Grid */}
        <div className="p-6">
          <div className="grid grid-cols-5 gap-4">
            {/* Date Headers */}
            {getWeekDates().map((date) => (
              <div 
                key={date.toISOString()} 
                className="text-center p-3 bg-gray-50 rounded-lg border border-gray-100"
              >
                <div className="font-semibold text-gray-800">{formatDate(date)}</div>
              </div>
            ))}

            {/* Time Slots */}
            {TIME_SLOTS.map(time => (
              getWeekDates().map(date => {
                const appointment = getAppointmentsForDateTime(date, time);
                const style = appointment ? getAppointmentStyle(appointment.type) : null;

                return (
                  <div 
                    key={`${date.toISOString()}-${time}`}
                    className="min-h-28 rounded-lg border border-gray-200 hover:border-blue-200 transition-colors"
                  >
                    <div className="p-2 border-b border-gray-100 bg-gray-50">
                      <span className="text-sm text-gray-500 flex items-center">
                        <Clock className="w-3 h-3 mr-1" />
                        {time}
                      </span>
                    </div>

                    {appointment && (
                      <div className={`m-2 p-3 rounded-lg ${style?.bg} ${style?.border} ${style?.text}`}>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center gap-2">
                            {style?.icon}
                            <span className="font-medium">{appointment.title}</span>
                          </div>
                          <div className="flex items-center text-gray-600">
                            <Users className="w-3 h-3 mr-1" />
                            <span className="text-xs">{appointment.attendees}</span>
                          </div>
                        </div>
                        <p className="text-sm mb-1 font-medium">{appointment.client}</p>
                        <p className="text-sm text-gray-600">{appointment.description}</p>
                        <div className="mt-2 text-xs text-gray-500 flex items-center">
                          <Clock className="w-3 h-3 mr-1" />
                          {appointment.startTime} - {appointment.endTime}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calender;
