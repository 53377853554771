import React, { useState } from 'react'
import { Send, Edit, XCircle } from 'react-feather'
import ApiService from '../../services/api.service'
import { Action, EmailAction, MessageAction } from '../../types'
import { Input } from '../forms/Input'

const service = new ApiService()

interface ActionContentProps {
    action: Action
    callId: string
    onSubmit: () => void
}

export const ActionContent: React.FC<ActionContentProps> = ({
    action,
    callId,
    onSubmit,
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedAction, setEditedAction] = useState(action)
    const [isSending, setIsSending] = useState(false)

    const handleSubmit = async () => {
        setIsSending(true)
        try {
            if (editedAction.action_type === 'send_email') {
                const emailAction = editedAction as EmailAction
                await service.sendEmail(
                    callId,
                    emailAction.user_email,
                    emailAction.email_subject,
                    emailAction.email_body,
                    emailAction.cc_emails
                        ? emailAction.cc_emails
                              .split(',')
                              .map(email => email.trim())
                        : [],
                )
            } else if (editedAction.action_type === 'send_sms') {
                const messageAction = editedAction as MessageAction
                await service.sendSMS(
                    callId,
                    messageAction.phone_number,
                    messageAction.message,
                )
            } else if (editedAction.action_type === 'send_whatsapp') {
                const messageAction = editedAction as MessageAction
                await service.sendWhatsApp(
                    callId,
                    messageAction.phone_number,
                    messageAction.message,
                )
            }
            onSubmit()
        } catch (error) {
            console.error('Error submitting action:', error)
            // Handle error (show toast or alert)
        } finally {
            setIsSending(false)
        }
    }

    return (
        <div className="mt-4 bg-gray-50 rounded-lg p-4">
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <h4 className="font-medium text-gray-700">
                        {action.action_type === 'send_email'
                            ? 'Email Content'
                            : 'Message Content'}
                    </h4>
                    <div className="flex items-center gap-2">
                        {isEditing ? (
                            <>
                                <button
                                    onClick={() => {
                                        setEditedAction(action)
                                        setIsEditing(false)
                                    }}
                                    className="text-gray-600 flex items-center gap-1 hover:text-gray-700">
                                    <XCircle className="w-4 h-4" />
                                    Cancel
                                </button>
                                <button
                                    onClick={() => setIsEditing(false)}
                                    className="text-blue-600 flex items-center gap-1 hover:text-blue-700">
                                    <Edit className="w-4 h-4" />
                                    Save
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="text-blue-600 flex items-center gap-1 hover:text-blue-700">
                                <Edit className="w-4 h-4" />
                                Edit
                            </button>
                        )}
                    </div>
                </div>

                {action.action_type === 'send_email' ? (
                    <div className="space-y-3">
                        <Input
                            type="text"
                            value={(editedAction as EmailAction).email_subject}
                            onChange={(e: any) =>
                                setEditedAction(
                                    prev =>
                                        ({
                                            ...prev,
                                            email_subject: e.value,
                                        }) as EmailAction,
                                )
                            }
                            disabled={!isEditing}
                            placeholder="Subject"
                        />
                        <Input
                            type="textarea"
                            value={(editedAction as EmailAction).email_body}
                            onChange={(e: any) =>
                                setEditedAction(
                                    prev =>
                                        ({
                                            ...prev,
                                            email_body: e.target.value,
                                        }) as EmailAction,
                                )
                            }
                            disabled={!isEditing}
                            rows={4}
                        />
                        <div className="grid grid-cols-2 gap-3">
                            <Input
                                type="email"
                                value={(editedAction as EmailAction).user_email}
                                onChange={(e: any) =>
                                    setEditedAction(
                                        prev =>
                                            ({
                                                ...prev,
                                                user_email: e.value,
                                            }) as EmailAction,
                                    )
                                }
                                disabled={!isEditing}
                                placeholder="To"
                            />
                            <Input
                                type="text"
                                value={
                                    (editedAction as EmailAction).cc_emails ||
                                    ''
                                }
                                onChange={(e: any) =>
                                    setEditedAction(
                                        prev =>
                                            ({
                                                ...prev,
                                                cc_emails: e.value,
                                            }) as EmailAction,
                                    )
                                }
                                disabled={!isEditing}
                                placeholder="CC"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="space-y-3">
                        <Input
                            type="textarea"
                            value={(editedAction as MessageAction).message}
                            onChange={(e: any) =>
                                setEditedAction(
                                    prev =>
                                        ({
                                            ...prev,
                                            message: e.target.value,
                                        }) as MessageAction,
                                )
                            }
                            disabled={!isEditing}
                            rows={4}
                        />
                        <Input
                            type="text"
                            value={(editedAction as MessageAction).phone_number}
                            onChange={(e: any) =>
                                setEditedAction(
                                    prev =>
                                        ({
                                            ...prev,
                                            phone_number: e.value,
                                        }) as MessageAction,
                                )
                            }
                            disabled={!isEditing}
                            placeholder="Phone Number"
                        />
                    </div>
                )}

                {!isEditing && (
                    <div className="mt-4 flex justify-end">
                        <button
                            onClick={handleSubmit}
                            disabled={isSending}
                            className={`bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700 ${
                                isSending ? 'opacity-50 cursor-not-allowed' : ''
                            }`}>
                            <Send className="w-4 h-4" />
                            {isSending ? 'Sending...' : 'Send Now'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
